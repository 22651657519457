import React, { useState, useRef, useEffect } from 'react';
import { KeyboardAvoidingView, Platform, Button, TextInput, ScrollView, Modal, View, StyleSheet, TouchableOpacity, FlatList, Easing, Animated} from 'react-native';
import { Text, Icon, Overlay } from 'react-native-elements';
import { NavigationContainer } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'; // Importa esta librería adicional
import SettingsScreen from './SettingsScreen'; // Asegúrate de que la ruta sea correcta
import { AntDesign, FontAwesome } from '@expo/vector-icons';// Importa el conjunto de iconos AntDesign desde la biblioteca @expo/vector-icons
import { LogBox } from 'react-native';
import { Alert, Clipboard } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Localization from 'expo-localization';
import { Image } from 'react-native'; // Importa el componente Image de react-native
import { Vibration } from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons'; 
import { Fontisto } from '@expo/vector-icons';
import { MaterialIcons } from '@expo/vector-icons';
import { ImageBackground } from 'react-native';
import Spinner from 'react-native-loading-spinner-overlay';
import getEnvVars from './environment';
import axios from 'axios';
import backgroundImage from './assets/images/background.jpg';
import TypingText from './components/TypingText'; // Ajusta la ruta según sea necesario
import SubscriptionScreen from './SubscriptionScreen'; // Importar el componente
import * as ImagePicker from 'expo-image-picker';



const deviceLanguage = Localization.locale.split('-')[0]; // Esto te dará, por ejemplo, 'en' si el idioma es 'en-US'
const greetings = {
  en: {
    general: "Hello! How can I help you today?",
    veterinarian: "Hello! How can I assist with your pet's health?",
    nutrición: "Hello! Need advice on nutrition?",
    training: "Hello! How can I assist with your training needs?"
  },

  es: {
    general: "¡Hola! ¿En qué puedo ayudarte hoy?",
    veterinarian: "¡Hola! ¿Cómo puedo ayudarte con la salud de tu mascota?",
    nutrición: "¡Hola! ¿Necesitas consejos sobre nutrición?",
    training: "¡Hola! ¿Cómo puedo ayudarte con tus necesidades de entrenamiento?"
  },
  de: {
    general: "Hallo! Wie kann ich Ihnen heute helfen?",
    veterinarian: "Hallo! Wie kann ich Ihnen bei der Gesundheit Ihres Haustieres helfen?",
    nutrición: "Hallo! Brauchen Sie Ernährungstipps?",
    training: "Hallo! Wie kann ich Ihnen bei Ihren Trainingsbedürfnissen helfen?"
  },
  fr: {
    general: "Bonjour! Comment puis-je vous aider aujourd'hui?",
    veterinarian: "Bonjour! Comment puis-je vous aider avec la santé de votre animal?",
    nutrición: "Bonjour! Besoin de conseils sur la nutrition?",
    training: "Bonjour! Comment puis-je vous aider avec vos besoins d'entraînement?"
  },
  it: {
    general: "Ciao! Come posso aiutarti oggi?",
    veterinarian: "Ciao! Come posso aiutarti con la salute del tuo animale?",
    nutrición: "Ciao! Hai bisogno di consigli sulla nutrizione?",
    training: "Ciao! Come posso aiutarti con le tue esigenze di allenamento?"
  },
  tr: {
    general: "Merhaba! Bugün size nasıl yardımcı olabilirim?",
    veterinarian: "Merhaba! Evcil hayvanınızın sağlığıyla nasıl yardımcı olabilirim?",
    nutrición: "Merhaba! Beslenme hakkında tavsiye mi arıyorsunuz?",
    training: "Merhaba! Eğitim ihtiyaçlarınızla nasıl yardımcı olabilirim?"
  }
};

const placeholders = {
  en: "Type your question here...",
  es: "Escribe aquí tu pregunta...",
  de: "Schreibe hier deine Frage...",
  fr: "Tapez votre question ici...",
  it: "Scrivi qui la tua domanda...",
  tr: "Buraya sorunuzu yazın..."
};

const saveChatTranslations = {
  en: "Save Chat",
  es: "Guardar Chat",
  de: "Chat speichern",
  fr: "Sauvegarder le Chat",
  it: "Salva Chat",
  tr: "Sohbeti Kaydet"
};


const languageDescriptions = {
  en: "English",
  es: "Español",
  de: "Deutsch",
  fr: "Français",
  it: "Italiano",
  tr: "Türkçe"
};

const initialGreeting = greetings[deviceLanguage]?.general || greetings['en'].general; // Si el idioma del dispositivo no está en la lista, usará el inglés como idioma predeterminado.

const translations = {
  en: {
    blocks: {
      health: "Health",
      training: "Training",
      nutrition: "Nutrition",
      recipes: "50 Recipes for Dogs", // Nueva sección: Recetas para perros
      travel: "Travel with Dogs" // Nueva sección: Viajes con perros
    },
    questions: [
      "How can I check my dog's health?",
      "What are common health issues in dogs?",
      "How do I train my dog to sit?",
      "What's the best way to potty train a puppy?",
      "What foods are toxic to dogs?",
      "How many meals should I feed my dog daily?",
      // Preguntas para la nueva sección "Recetas para perros"
      "Do you have any healthy recipes for dogs?",
      "What are some homemade dog treat recipes?",
      "How can I make homemade dog food?",
      "Can you suggest a recipe for homemade dog biscuits?",
      "What are some simple recipes for dog-friendly meals?",
      "Are there any easy-to-make dog food recipes?",
      "What are some dog-friendly vegetable recipes?",
      "Can I feed my dog cooked chicken and rice?",
      "How do I make dog-friendly peanut butter treats?",
      "What's a good recipe for dog-friendly pumpkin snacks?",
      "Do you have a recipe for dog-friendly sweet potato treats?",
      "Can I make my own dog food with turkey and vegetables?",
      "What are some healthy recipes for senior dogs?",
      "How do I prepare a dog-friendly chicken stew?",
      "What's a safe homemade dog food recipe using beef?",
      "Can I cook fish for my dog's meals?",
      "What are some dog-friendly meatball recipes?",
      "How do I make frozen dog treats at home?",
      "Do you have a recipe for dog-friendly carrot and apple snacks?",
      "What's a good recipe for dog-friendly blueberry biscuits?",
      "Can I make dog food with ground lamb and rice?",
      "How do I prepare a dog-friendly omelette?",
      "What are some recipes for dog-friendly smoothies?",
      "How can I make dog-friendly frozen yogurt treats?",
      "Do you have a recipe for dog-friendly spinach and cheese bites?",
      "What's a good recipe for homemade dog-friendly jerky?",
      "Can I make dog food with pork and veggies?",
      "How do I prepare dog-friendly scrambled eggs?",
      "What are some dog-friendly liver treat recipes?",
      "How can I make dog-friendly pumpkin and banana muffins?",
      "Do you have a recipe for dog-friendly cheese and bacon biscuits?",
      "What's a good recipe for dog-friendly cranberry snacks?",
      "Can I make my own dog food with venison and sweet potatoes?",
      "How do I prepare a dog-friendly quiche?",
      "What are some recipes for dog-friendly pupcakes?",
      "How can I make dog-friendly frozen fruit popsicles?",
      "Do you have a recipe for dog-friendly coconut and honey treats?",
      "What's a good recipe for dog-friendly cheddar biscuits?",
      "Can I make dog food with rabbit and veggies?",
      "How do I prepare dog-friendly mini meatloaves?",
      "What are some dog-friendly banana and peanut butter snacks?",
      "How can I make dog-friendly frozen apple bites?",
      "Do you have a recipe for dog-friendly turkey and cranberry biscuits?",
      "What's a good recipe for dog-friendly sweet potato and cinnamon treats?",
      "Can I make my own dog food with bison and vegetables?",
      "How do I prepare a dog-friendly vegetable frittata?",
      "What are some recipes for dog-friendly birthday cakes?",
      "How can I make dog-friendly frozen chicken treats?",
      "Do you have a recipe for dog-friendly pumpkin and yogurt bites?",
      "What's a good recipe for dog-friendly coconut and banana biscuits?",
      // Preguntas para la nueva sección "Viajes con perros"
      "How can I prepare my dog for travel?",
      "What are the best travel tips for dogs?"
    ]
  },
  es: {
    blocks: {
      health: "Salud",
      training: "Entrenamiento",
      nutrition: "Nutrición",
      recipes: "50 Recetas para perros", // Nueva sección: Recetas para perros
      travel: "Viajes con perros" // Nueva sección: Viajes con perros
    },
    questions: [
      "¿Cómo puedo revisar la salud de mi perro?",
      "¿Cuáles son los problemas de salud más comunes en los perros?",
      "¿Cómo enseño a mi perro a sentarse?",
      "¿Cuál es la mejor manera de enseñar a un cachorro a ir al baño?",
      "¿Qué alimentos son tóxicos para los perros?",
      "¿Cuántas comidas debo darle a mi perro al día?",
      // Preguntas para la nueva sección "Recetas para perros"
      "¿Tienes alguna receta saludable para perros?",
      "¿Cuáles son algunas recetas caseras de golosinas para perros?",
      "¿Cómo puedo hacer comida casera para perros?",
      "¿Puedes sugerir una receta para galletas caseras para perros?",
      "¿Cuáles son algunas recetas simples para comidas aptas para perros?",
      "¿Hay recetas fáciles de hacer para la comida de perros?",
      "¿Cuáles son algunas recetas de vegetales para perros?",
      "¿Puedo alimentar a mi perro con pollo y arroz cocidos?",
      "¿Cómo puedo hacer golosinas de mantequilla de maní aptas para perros?",
      "¿Cuál es una buena receta para snacks de calabaza para perros?",
      "¿Tienes una receta para golosinas de batata para perros?",
      "¿Puedo hacer comida casera para perros con pavo y vegetales?",
      "¿Cuáles son algunas recetas saludables para perros mayores?",
      "¿Cómo preparo un guiso de pollo apto para perros?",
      "¿Cuál es una receta segura de comida casera para perros usando carne de res?",
      "¿Puedo cocinar pescado para las comidas de mi perro?",
      "¿Cuáles son algunas recetas de albóndigas aptas para perros?",
      "¿Cómo hago golosinas congeladas para perros en casa?",
      "¿Tienes una receta para snacks de zanahoria y manzana aptos para perros?",
      "¿Cuál es una buena receta para galletas de arándanos aptas para perros?",
      "¿Puedo hacer comida para perros con cordero molido y arroz?",
      "¿Cómo preparo una tortilla apta para perros?",
      "¿Cuáles son algunas recetas de batidos aptos para perros?",
      "¿Cómo puedo hacer golosinas de yogur congeladas para perros?",
      "¿Tienes una receta para bocaditos de espinaca y queso aptos para perros?",
      "¿Cuál es una buena receta para jerky casero apto para perros?",
      "¿Puedo hacer comida para perros con cerdo y vegetales?",
      "¿Cómo preparo huevos revueltos aptos para perros?",
      "¿Cuáles son algunas recetas de snacks de hígado para perros?",
      "¿Cómo puedo hacer muffins de calabaza y plátano aptos para perros?",
      "¿Tienes una receta para galletas de queso y tocino aptas para perros?",
      "¿Cuál es una buena receta para snacks de arándanos aptos para perros?",
      "¿Puedo hacer comida casera para perros con venado y batatas?",
      "¿Cómo preparo una quiche apta para perros?",
      "¿Cuáles son algunas recetas de pupcakes aptas para perros?",
      "¿Cómo puedo hacer paletas de frutas congeladas aptas para perros?",
      "¿Tienes una receta para golosinas de coco y miel aptas para perros?",
      "¿Cuál es una buena receta para galletas de cheddar aptas para perros?",
      "¿Puedo hacer comida para perros con conejo y vegetales?",
      "¿Cómo preparo mini albóndigas aptas para perros?",
      "¿Cuáles son algunas recetas de banana y mantequilla de maní aptas para perros?",
      "¿Cómo puedo hacer bocaditos de manzana congelados aptos para perros?",
      "¿Tienes una receta para galletas de pavo y arándanos aptas para perros?",
      "¿Cuál es una buena receta para golosinas de batata y canela aptas para perros?",
      "¿Puedo hacer comida casera para perros con bisonte y vegetales?",
      "¿Cómo preparo una frittata de vegetales apta para perros?",
      "¿Cuáles son algunas recetas de pasteles de cumpleaños aptas para perros?",
      "¿Cómo puedo hacer golosinas de pollo congeladas aptas para perros?",
      "¿Tienes una receta para bocaditos de calabaza y yogur aptos para perros?",
      "¿Cuál es una buena receta para galletas de coco y plátano aptas para perros?",
      // Preguntas para la nueva sección "Viajes con perros"
      "¿Cómo puedo preparar a mi perro para viajar?",
      "¿Cuáles son los mejores consejos de viaje para perros?"
    ]
  },
  de: {
    blocks: {
      health: "Gesundheit",
      training: "Training",
      nutrition: "Ernährung",
      recipes: "50 Rezepte für Hunde", // Nueva sección: Recetas para perros
      travel: "Reisen mit Hunden" // Nueva sección: Viajes con perros
    },
    questions: [
      "Wie kann ich die Gesundheit meines Hundes überprüfen?",
      "Welche sind häufige Gesundheitsprobleme bei Hunden?",
      "Wie bringe ich meinem Hund bei, sich zu setzen?",
      "Wie bringt man einem Welpen am besten das Töpfchentraining bei?",
      "Welche Lebensmittel sind für Hunde giftig?",
      "Wie viele Mahlzeiten sollte ich meinem Hund täglich geben?",
      // Preguntas para la nueva sección "Recetas para perros"
      "Hast du ein gesundes Rezept für Hunde?",
      "Was sind einige hausgemachte Rezepte für Hunde-Leckerlis?",
      "Wie kann ich selbstgemachtes Hundefutter herstellen?",
      "Kannst du ein Rezept für selbstgemachte Hundekekse vorschlagen?",
      "Was sind einige einfache Rezepte für hundefreundliche Mahlzeiten?",
      "Gibt es einfache Rezepte für Hundefutter?",
      "Was sind einige hundefreundliche Gemüserezepte?",
      "Kann ich meinem Hund gekochtes Huhn und Reis füttern?",
      "Wie mache ich hundefreundliche Erdnussbutter-Leckerlis?",
      "Was ist ein gutes Rezept für hundefreundliche Kürbissnacks?",
      "Hast du ein Rezept für hundefreundliche Süßkartoffel-Leckerlis?",
      "Kann ich mein eigenes Hundefutter mit Truthahn und Gemüse machen?",
      "Was sind einige gesunde Rezepte für ältere Hunde?",
      "Wie bereite ich einen hundefreundlichen Hühnereintopf zu?",
      "Was ist ein sicheres selbstgemachtes Hundefutter-Rezept mit Rindfleisch?",
      "Kann ich Fisch für die Mahlzeiten meines Hundes kochen?",
      "Was sind einige hundefreundliche Fleischbällchen-Rezepte?",
      "Wie mache ich hausgemachte gefrorene Leckerlis für Hunde?",
      "Hast du ein Rezept für hundefreundliche Karotten- und Apfelsnacks?",
      "Was ist ein gutes Rezept für hundefreundliche Heidelbeerkekse?",
      "Kann ich Hundefutter mit gemahlenem Lamm und Reis zubereiten?",
      "Wie bereite ich ein hundefreundliches Omelett zu?",
      "Was sind einige Rezepte für hundefreundliche Smoothies?",
      "Wie kann ich hundefreundliche gefrorene Joghurt-Leckerlis machen?",
      "Hast du ein Rezept für hundefreundliche Spinat- und Käsebällchen?",
      "Was ist ein gutes Rezept für selbstgemachtes hundefreundliches Trockenfleisch?",
      "Kann ich Hundefutter mit Schweinefleisch und Gemüse machen?",
      "Wie bereite ich hundefreundliche Rühreier zu?",
      "Was sind einige hundefreundliche Leber-Leckerli-Rezepte?",
      "Wie kann ich hundefreundliche Kürbis-Bananen-Muffins machen?",
      "Hast du ein Rezept für hundefreundliche Käse-Speck-Kekse?",
      "Was ist ein gutes Rezept für hundefreundliche Cranberry-Snacks?",
      "Kann ich mein eigenes Hundefutter mit Hirsch und Süßkartoffeln machen?",
      "Wie bereite ich eine hundefreundliche Quiche zu?",
      "Was sind einige Rezepte für hundefreundliche Welpenkuchen?",
      "Wie kann ich hundefreundliche gefrorene Fruchteis am Stiel machen?",
      "Hast du ein Rezept für hundefreundliche Kokosnuss- und Honig-Leckerlis?",
      "Was ist ein gutes Rezept für hundefreundliche Cheddar-Kekse?",
      "Kann ich Hundefutter mit Kaninchen und Gemüse machen?",
      "Wie bereite ich hundefreundliche Mini-Frikadellen zu?",
      "Was sind einige hundefreundliche Bananen- und Erdnussbutter-Snacks?",
      "Wie kann ich hundefreundliche gefrorene Apfelbissen machen?",
      "Hast du ein Rezept für hundefreundliche Puten- und Cranberry-Kekse?",
      "Was ist ein gutes Rezept für hundefreundliche Süßkartoffel-Zimt-Leckerlis?",
      "Kann ich mein eigenes Hundefutter mit Bison und Gemüse machen?",
      "Wie bereite ich eine hundefreundliche Gemüse-Frittata zu?",
      "Was sind einige Rezepte für hundefreundliche Geburtstagstorten?",
      "Wie kann ich hundefreundliche gefrorene Hühnchen-Leckerlis machen?",
      "Hast du ein Rezept für hundefreundliche Kürbis- und Joghurt-Bissen?",
      "Was ist ein gutes Rezept für hundefreundliche Kokosnuss- und Bananenkekse?",
      // Preguntas para la nueva sección "Viajes con perros"
      "Wie kann ich meinen Hund auf Reisen vorbereiten?",
      "Was sind die besten Reisetipps für Hunde?"
    ]
  },
  fr: {
    blocks: {
      health: "Santé",
      training: "Entraînement",
      nutrition: "Nutrition",
      recipes: "50 Recettes pour chiens", // Nueva sección: Recetas para perros
      travel: "Voyager avec des chiens" // Nueva sección: Viajes con perros
    },
    questions: [
      "Comment vérifier la santé de mon chien?",
      "Quels sont les problèmes de santé courants chez les chiens?",
      "Comment apprendre à mon chien à s'asseoir?",
      "Quelle est la meilleure façon d'apprendre la propreté à un chiot?",
      "Quels aliments sont toxiques pour les chiens?",
      "Combien de repas dois-je donner à mon chien par jour?",
      // Preguntas para la nueva sección "Recetas para perros"
      "Avez-vous des recettes saines pour les chiens?",
      "Quelles sont quelques recettes maison de friandises pour chiens?",
      "Comment puis-je préparer de la nourriture pour chien faite maison?",
      "Pouvez-vous suggérer une recette de biscuits pour chien faits maison?",
      "Quelles sont quelques recettes simples pour les repas adaptés aux chiens?",
      "Existe-t-il des recettes faciles pour la nourriture pour chien?",
      "Quelles sont quelques recettes de légumes adaptées aux chiens?",
      "Puis-je nourrir mon chien avec du poulet cuit et du riz?",
      "Comment préparer des friandises au beurre de cacahuète pour chien?",
      "Quelle est une bonne recette pour des en-cas à base de potiron adaptés aux chiens?",
      "Avez-vous une recette pour des friandises à la patate douce pour chiens?",
      "Puis-je préparer ma propre nourriture pour chien avec de la dinde et des légumes?",
      "Quelles sont quelques recettes saines pour les chiens âgés?",
      "Comment préparer un ragoût de poulet adapté aux chiens?",
      "Quelle est une recette sûre de nourriture pour chien faite maison avec du bœuf?",
      "Puis-je cuisiner du poisson pour les repas de mon chien?",
      "Quelles sont quelques recettes de boulettes de viande adaptées aux chiens?",
      "Comment faire des friandises pour chiens congelées à la maison?",
      "Avez-vous une recette pour des en-cas à base de carottes et de pommes adaptés aux chiens?",
      "Quelle est une bonne recette de biscuits aux myrtilles pour chiens?",
      "Puis-je préparer de la nourriture pour chien avec de l'agneau haché et du riz?",
      "Comment préparer une omelette adaptée aux chiens?",
      "Quelles sont quelques recettes de smoothies adaptées aux chiens?",
      "Comment faire des friandises pour chiens congelées au yaourt à la maison?",
      "Avez-vous une recette pour des bouchées aux épinards et au fromage adaptées aux chiens?",
      "Quelle est une bonne recette de jerky pour chiens faite maison?",
      "Puis-je faire de la nourriture pour chien avec du porc et des légumes?",
      "Comment préparer des œufs brouillés adaptés aux chiens?",
      "Quelles sont quelques recettes de friandises au foie adaptées aux chiens?",
      "Comment préparer des muffins à la citrouille et à la banane adaptés aux chiens?",
      "Avez-vous une recette pour des biscuits au fromage et au bacon adaptés aux chiens?",
      "Quelle est une bonne recette de biscuits aux canneberges pour chiens?",
      "Puis-je préparer ma propre nourriture pour chien avec du cerf et des patates douces?",
      "Comment préparer une quiche adaptée aux chiens?",
      "Quelles sont quelques recettes de gâteaux d'anniversaire adaptées aux chiens?",
      "Comment faire des friandises pour chiens congelées au poulet à la maison?",
      "Avez-vous une recette pour des bouchées au potiron et au yaourt adaptées aux chiens?",
      "Quelle est une bonne recette de biscuits à la noix de coco et à la banane pour chiens?",
      // Preguntas para la nueva sección "Viajes con perros"
      "Comment puis-je préparer mon chien pour le voyage?",
      "Quels sont les meilleurs conseils de voyage pour les chiens?"
    ]
  },
  it: {
    blocks: {
      health: "Salute",
      training: "Allenamento",
      nutrition: "Nutrizione",
      recipes: "50 Ricette per cani", // Nueva sección: Recetas para perros
      travel: "Viaggiare con i cani" // Nueva sección: Viajes con perros
    },
    questions: [
      "Come posso controllare la salute del mio cane?",
      "Quali sono i problemi di salute più comuni nei cani?",
      "Come posso addestrare il mio cane a sedersi?",
      "Qual è il modo migliore per addestrare un cucciolo a fare i bisogni fuori?",
      "Quali cibi sono tossici per i cani?",
      "Quante volte al giorno dovrei nutrire il mio cane?",
      // Preguntas para la nueva sección "Recetas para perros"
      "Hai delle ricette salutari per cani?",
      "Quali sono alcune ricette casalinghe per gli snack dei cani?",
      "Come posso preparare del cibo fatto in casa per il mio cane?",
      "Puoi suggerire una ricetta per biscotti fatti in casa per cani?",
      "Quali sono alcune ricette semplici per pasti adatti ai cani?",
      "Esistono ricette facili per il cibo per cani?",
      "Quali sono alcune ricette di verdure adatte ai cani?",
      "Posso dare al mio cane pollo cotto e riso?",
      "Come posso preparare degli snack al burro di arachidi per cani?",
      "Qual è una buona ricetta per degli snack alla zucca per cani?",
      "Hai una ricetta per degli snack dolci alla patata dolce per cani?",
      "Posso preparare il cibo per cani con tacchino e verdure?",
      "Quali sono alcune ricette salutari per cani anziani?",
      "Come preparo uno stufato di pollo adatto ai cani?",
      "Qual è una ricetta sicura per il cibo fatto in casa con carne di manzo per cani?",
      "Posso cucinare pesce per i pasti del mio cane?",
      "Quali sono alcune ricette di polpette adatte ai cani?",
      "Come posso fare degli snack congelati per cani a casa?",
      "Hai una ricetta per degli snack alle carote e mele per cani?",
      "Qual è una buona ricetta per biscotti ai mirtilli per cani?",
      "Posso preparare il cibo per cani con agnello macinato e riso?",
      "Come preparo una frittata adatta ai cani?",
      "Quali sono alcune ricette di frullati adatte ai cani?",
      "Come posso fare degli snack al gelato per cani a casa?",
      "Hai una ricetta per delle bocconcini agli spinaci e formaggio per cani?",
      "Qual è una buona ricetta per jerky per cani fatto in casa?",
      "Posso preparare il cibo per cani con maiale e verdure?",
      "Come preparo delle uova strapazzate adatte ai cani?",
      "Quali sono alcune ricette di snack al fegato adatte ai cani?",
      "Come posso fare delle muffin alla zucca e banana adatti ai cani?",
      "Hai una ricetta per dei biscotti al formaggio e pancetta per cani?",
      "Qual è una buona ricetta per snack alle cranberry per cani?",
      "Posso preparare il mio cibo per cani con carne di cervo e patate dolci?",
      "Come preparo una quiche adatta ai cani?",
      "Quali sono alcune ricette di torte di compleanno adatte ai cani?",
      "Come posso fare degli snack al pollo congelati per cani a casa?",
      "Hai una ricetta per degli snack alla zucca e yogurt per cani?",
      "Qual è una buona ricetta per biscotti alla cocco e banana per cani?",
      // Preguntas para la nueva sección "Viajes con perros"
      "Come posso preparare il mio cane per un viaggio?",
      "Quali sono i migliori consigli per viaggiare con i cani?"
    ]
  },
  tr: {
    blocks: {
      health: "Sağlık",
      training: "Eğitim",
      nutrition: "Beslenme",
      recipes: "50 Köpek Tarifleri", // Nueva sección: Recetas para perros
      travel: "Köpeklerle Seyahat" // Nueva sección: Viajes con perros
    },
    questions: [
      "Köpeğimin sağlığını nasıl kontrol edebilirim?",
      "Köpeklerde sık rastlanan sağlık sorunları nelerdir?",
      "Köpeğime oturmayı nasıl öğretebilirim?",
      "Bir yavru köpeği tuvalet eğitimi için en iyi yöntem nedir?",
      "Hangi gıdalar köpekler için toksiktir?",
      "Köpeğime günde kaç öğün yemek vermeliyim?",
      // Preguntas para la nueva sección "Recetas para perros"
      "Sağlıklı köpek tarifleriniz var mı?",
      "Ev yapımı köpek ödülü tariflerinden bazıları nelerdir?",
      "Evde nasıl köpek maması yapabilirim?",
      "Evde yapabileceğim köpek bisküvisi tarifi önerebilir misiniz?",
      "Köpek dostu yemekler için basit tarifler nelerdir?",
      "Köpek maması için kolay tarifler var mı?",
      "Köpek dostu sebze tariflerinden bazıları nelerdir?",
      "Köpeğime pişmiş tavuk ve pirinç verebilir miyim?",
      "Köpek dostu fıstık ezmesi tarifini nasıl yaparım?",
      "Köpek dostu balkabağı atıştırmalığı için iyi bir tarif nedir?",
      "Köpek dostu tatlı patates atıştırmalığı için bir tarifiniz var mı?",
      "Hindi ve sebzelerle kendi köpek mamasımı yapabilir miyim?",
      "Yaşlı köpekler için sağlıklı tarifler nelerdir?",
      "Köpek dostu tavuk güvecini nasıl hazırlarım?",
      "Köpek için kendi yemek tarifimi nasıl yapabilirim?",
      "Köpeğimin yemekleri için balık pişirebilir miyim?",
      "Köpek dostu köfte tariflerinden bazıları nelerdir?",
      "Evde dondurulmuş köpek atıştırmalıkları nasıl yapabilirim?",
      "Köpek dostu havuç ve elma atıştırmalığı için bir tarifiniz var mı?",
      "Köpek dostu böğürtlen bisküvisi için iyi bir tarif nedir?",
      "Kuzu kıyma ve pirinçle kendi köpek mamasımı yapabilir miyim?",
      "Köpek dostu omlet nasıl yapılır?",
      "Köpek dostu smoothie tariflerinden bazıları nelerdir?",
      "Köpek dostu donmuş yoğurt atıştırmalıklarını nasıl yapabilirim?",
      "Köpek dostu ıspanak ve peynirli atıştırmalık tarifiniz var mı?",
      "Ev yapımı köpek dostu jöle için iyi bir tarif nedir?",
      "Domuz eti ve sebzelerle kendi köpek mamasımı yapabilir miyim?",
      "Köpek dostu karışık yumurta nasıl hazırlanır?",
      "Köpek dostu karaciğer atıştırmalık tariflerinizden bazıları nelerdir?",
      "Köpek dostu balkabağı ve muz muffini nasıl yapabilirim?",
      "Köpek dostu peynir ve bacon bisküvisi için bir tarifiniz var mı?",
      "Köpek dostu kızılcık atıştırmalığı için iyi bir tarif nedir?",
      "Geyik eti ve tatlı patatesle kendi köpek mamasımı yapabilir miyim?",
      "Köpek dostu tuzlu kek nasıl hazırlanır?",
      "Köpek dostu doğum günü pastası tariflerinden bazıları nelerdir?",
      "Köpek dostu dondurulmuş tavuk atıştırmalıklarını nasıl yapabilirim?",
      "Köpek dostu balkabağı ve yoğurt atıştırmalığı için bir tarifiniz var mı?",
      "Köpek dostu hindistancevizi ve muz bisküvisi için iyi bir tarif nedir?",
      // Preguntas para la nueva sección "Viajes con perros"
      "Köpeğimi seyahate nasıl hazırlayabilirim?",
      "Köpekler için en iyi seyahat ipuçları nelerdir?"
    ]
  }
};

const historyTranslations = {
  en: {
    noConversations: "No conversations to recover",
    savedConversation: "Conversation saved successfully"
  },
  es: {
    noConversations: "No hay conversaciones por recuperar",
    savedConversation: "Conversación guardada exitosamente"
  },
  de: {
    noConversations: "Keine Gespräche zum Wiederherstellen",
    savedConversation: "Gespräch erfolgreich gespeichert"
  },
  fr: {
    noConversations: "Aucune conversation à récupérer",
    savedConversation: "Conversation enregistrée avec succès"
  },
  it: {
    noConversations: "Nessuna conversazione da recuperare",
    savedConversation: "Conversazione salvata con successo"
  },
  tr: {
    noConversations: "Kurtarılacak konuşma yok",
    savedConversation: "Konuşma başarıyla kaydedildi"
  }
};
const currentTranslations = translations[deviceLanguage] || translations.en; // Si no hay traducciones para el idioma del dispositivo, se usará el inglés por defecto


LogBox.ignoreLogs([
  "Non-serializable values were found in the navigation state.",
  "Constants.platform.ios.model has been deprecated",
]);



const { OPENAI_API_KEY } = getEnvVars();


const getGPTResponse = async (userMessage, chatHistory = [], responseMode) => {
  const apiKey = OPENAI_API_KEY;
  const apiUrl = "https://api.openai.com/v1/chat/completions";

  let systemMessage;
  let maxTokens = 450; // Valor por defecto

  switch (responseMode) {
    case "general":
      systemMessage = "You are a general expert on dog care. Keep answers short and to the point.Respond step by step";
      maxTokens = 450;
      break;
    case "veterinario":
      systemMessage = "You are a veterinarian specializing in dog health. Keep answers short and to the point.Respond step by step";
      maxTokens = 450;
      break;
    case "nutrición":
      systemMessage = "You are a dog nutrition specialist. Keep answers short and to the point.Respond step by step";
      maxTokens = 450;
      break;
    case "training":
      systemMessage = "You are an expert on dog training. Keep answers short and to the point.Respond step by step";
      maxTokens = 450;
      break;
    default:
      systemMessage = "You are an expert on dog care. Provide short answers. Keep answers short and to the point.Respond step by step";
      break;
  }

  const allMessages = [
    {
      role: "system",
      content: systemMessage
    },
    ...chatHistory,
    {
      role: "user",
      content: userMessage
    }
  ];

  try {
    const response = await axios.post(apiUrl, {
      model: "gpt-3.5-turbo-0613",
      messages: allMessages,
      max_tokens: maxTokens
    }, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${apiKey}`
      }
    });

    if (response.status !== 200) {
      throw new Error(`API response error: ${response.statusText}`);
    }

    const result = response.data;
    if (result.choices && result.choices[0] && result.choices[0].message) {
      return result.choices[0].message.content.trim();
    } else {
      throw new Error("Unexpected API response format");
    }
  } catch (error) {
    console.error("Error getting GPT response:", error);
    return "I'm sorry, I couldn't process your message, please try again.";
  }
};



const ChatBotComponent = ({ route, isNightMode, clearInitialMessage, vibrationEnabled, messageHistory, setMessageHistory, initialUserMessage, temperature, responseMode, handleSaveConversation, selectedLanguage, handleQuestionIconPress, navigation }) => {
  const { chatId } = route.params;

  const [userMessage, setUserMessage] = useState(initialUserMessage || "");
  const [chatHistory, setChatHistory] = useState(messageHistory);
  const [isBotProcessing, setIsBotProcessing] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const scrollViewRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  
  

  const showOptions = (message, index) => {
    Alert.alert(
        "Opciones del mensaje",
        "",
        [
            {
                text: "Copiar",
                onPress: () => Clipboard.setString(message)
            },
            {
                text: "Eliminar",
                onPress: () => handleDeleteMessage(index)
            },
            {
                text: "Cancelar",
                style: "cancel"
            }
        ]
    );
  };
  const handleDeleteMessage = (index) => {
    const newChatHistory = [...chatHistory];
    newChatHistory.splice(index, 1);
    setChatHistory(newChatHistory);
  };

  const storeChatHistory = async (chatId, history) => {
    try {
      await AsyncStorage.setItem(`chatHistory_${chatId}`, JSON.stringify(history));
    } catch (error) {
      console.error("Error storing chat history:", error);
    }
  };
  
  const retrieveChatHistory = async (chatId) => {
    try {
      const storedChatHistory = await AsyncStorage.getItem(`chatHistory_${chatId}`);
      if (storedChatHistory) {
        return JSON.parse(storedChatHistory);
      }
      return null;
    } catch (error) {
      console.error("Error retrieving chat history:", error);
      return null;
    }
  };

  const handleSendMessage = async () => {
    try {
      setIsBotProcessing(true);
  
      // Crear el mensaje del usuario y obtener la respuesta del bot
      const userChatItem = { role: 'user', content: userMessage };
      const gptResponse = await getGPTResponse(userMessage, chatHistory, responseMode);
      const gptChatItem = { role: 'assistant', content: gptResponse };
    
  
      // Actualizar el historial de chat local (estado)
      const updatedChatHistory = [...chatHistory, userChatItem, gptChatItem];
      setChatHistory(updatedChatHistory);
  
      // Limpiar el mensaje del usuario
      setUserMessage("");
  
      // Desactivar el indicador de procesamiento del bot
      setIsBotProcessing(false);
  
      // Vibrar (si está habilitado)
      if (vibrationEnabled) {
        Vibration.vibrate();
      }
  
      // Actualizar el historial de mensajes global
      setMessageHistory((prevHistory) => ({
        ...prevHistory,
        [chatId]: updatedChatHistory
      }));
  
      // Limpiar el mensaje inicial (si lo hay)
      clearInitialMessage();
  
      // Desplazarse hacia el final del chat
      if (scrollViewRef.current) {
        const scrollPosition = scrollViewRef.current.scrollHeight - 200;
        scrollViewRef.current.scrollTo({ y: scrollPosition, animated: true });
      }
  
      // Almacenar el historial de chat actualizado en AsyncStorage
      storeChatHistory(chatId, updatedChatHistory);
  
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  useEffect(() => {
    console.log('Storing chat history:', chatHistory);
    storeChatHistory(chatId, chatHistory);
    if (scrollViewRef.current && scrollViewRef.current.scrollToEnd) {
      scrollViewRef.current.scrollToEnd({ animated: true });
    }
  }, [chatHistory, chatId]);


  useEffect(() => {
    const loadStoredChatHistory = async () => {
      const storedHistory = await retrieveChatHistory(chatId);
      console.log('Loaded stored history:', storedHistory);
      if (storedHistory) {
        setChatHistory(storedHistory);
      } else {
        // Si no hay un historial almacenado para el chatId actual, restablecer al estado inicial
        const newGreeting = greetings[selectedLanguage][responseMode] || greetings['en'][responseMode];
        setChatHistory([{ role: 'assistant', content: newGreeting }]);

      }
    };
  
    
    loadStoredChatHistory();
  }, [chatId]);

  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const nightModeBotAvatarURL = 'https://app.hundezonen.ch/docs/icons8-bot-48.png';
  const regularBotAvatarURL ='https://app.hundezonen.ch/docs/icons9-bot-48.png';
  const [userAvatarURL, setUserAvatarURL] = useState(null);
  const [fadeIn] = useState(new Animated.Value(0)); // Inicialmente, la opacidad está en 0
  const [showText, setShowText] = useState(true);  // Inicialmente, mostramos el texto
  useEffect(() => {
    setTimeout(() => {
      Animated.timing(fadeIn, {
        toValue: 1, // Finalmente, la opacidad será 1 (completamente visible)
        duration: 2000, // Duración de la animación
        useNativeDriver: true, // Utiliza la animación nativa
      }).start(() => setShowText(false)); // Al finalizar la animación, ocultamos el texto
    }, 1000);
  }, []);
  
  
  // Recuperar avatar de AsyncStorage
  const fetchAvatar = async () => {
    try {
      const storedAvatar = await AsyncStorage.getItem('userAvatarURL');
      if (storedAvatar !== null) {
        setUserAvatarURL(storedAvatar);
      }
    } catch (error) {
      console.error("Error al recuperar el avatar:", error);
    }
  };
  
  // Efecto para cargar el avatar al montar el componente
  useEffect(() => {
    fetchAvatar();
  }, []);
  
// Primera función para pedir permiso
const requestPhotoPermission = async () => {
  const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();

  if (status !== 'granted') {
      alert('Lo sentimos, necesitamos permisos de cámara para hacer esto funcionar.');
  } else {
      // Si se concede el permiso, lanzamos el selector de imágenes
      handleUploadAvatar();
  }
};

// Segunda función para lanzar el selector de imágenes
const handleUploadAvatar = async () => {
  let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
      base64: true
  });

  if (!result.canceled && result.assets && result.assets.length > 0) {
      const selectedImage = result.assets[0];
      if (!selectedImage.base64) {
          console.error("No se pudo obtener el base64 de la imagen seleccionada.");
          return;
      }
      const base64 = `data:${selectedImage.type};base64,${selectedImage.base64}`;
      console.log(base64);
      setUserAvatarURL(base64);
      storeAvatar(base64);
  }
};
  
  // Guardar avatar en AsyncStorage
  const storeAvatar = async (avatarURL) => {
    try {
      await AsyncStorage.setItem('userAvatarURL', avatarURL);
    } catch (error) {
      console.error("Error al guardar el avatar:", error);
    }
  };



   return (
    
    <KeyboardAvoidingView 
      behavior={Platform.OS === "ios" ? "padding" : undefined}
      keyboardVerticalOffset={Platform.OS === "ios" ? 80 : 0}
      style={[styles.chatContainer, isNightMode && styles.nightModeContainer]}
    >
      <KeyboardAwareScrollView
        ref={scrollViewRef}
        style={[styles.chatScrollView, isNightMode && styles.nightModeScrollView]}
        contentContainerStyle={[styles.chatScrollViewContent, isNightMode && styles.nightModeScrollViewContent]}
      >
        {chatHistory.map((chatItem, index) => (
          <TouchableOpacity key={index} onPress={() => showOptions(chatItem.content, index)}>
            <View style={[
              chatItem.role === 'user' ? styles.userMessageContainer : styles.gptMessageContainer,
              isNightMode && { backgroundColor: '#555' }
            ]}>
              {chatItem.role === 'assistant' && (
                <Image 
                  source={{ uri: isNightMode ? nightModeBotAvatarURL : regularBotAvatarURL }}
                  style={styles.botAvatar} 
                />
              )}
              
                {chatItem.role === 'user' && (
                <TouchableOpacity onPress={requestPhotoPermission}>
                {!userAvatarURL ? 
               <Ionicons name="add-circle" size={40} color="blue" /> :
              <Image source={{ uri: userAvatarURL }} style={styles.userAvatar} />
                }
    </TouchableOpacity>
    
)}


              <Text style={[
                chatItem.role === 'user' ? styles.userMessage : styles.gptMessage,
                isNightMode ? { color: '#fff' } : { color: '#000' }
              ]}>
                {chatItem.content}
              </Text>
            </View>
          </TouchableOpacity>
        ))}
           </KeyboardAwareScrollView>
      <View style={styles.inputContainer}>


      <TouchableOpacity 
  onPress={() => handleQuestionIconPress(navigation)} 
  style={styles.helpIcon}
>
  <Ionicons name="document-text" size={26} color={isNightMode ? '#b5b5b5' : '#009688'} />
</TouchableOpacity>

          
  <TextInput
    style={[
      styles.input,
      isNightMode ? { backgroundColor: '#555', color: '#fff' } : { backgroundColor: '#fff', color: '#000' }
    ]}
    value={userMessage}
    onChangeText={text => {
      setUserMessage(text);
      setRefresh(prev => !prev);
    }}
    placeholder={`Message...`}
    placeholderTextColor={isNightMode ? '#ccc' : '#888'}
    multiline={true} // Permitir múltiples líneas
    numberOfLines={5} // Límite de 5 líneas
  />
  <Spinner // Use Spinner for loading animation
    visible={isBotProcessing} // Show spinner when isBotProcessing is true
    textContent={'Processing'} // Text shown under the spinner
    textStyle={{ color: isNightMode ? '#fff' : '#000' }} // Text style
    color={isNightMode ? '#fff' : 'darkgreen'} // Spinner color
  />
  {!isBotProcessing && (
    userMessage ? (
      <TouchableOpacity
        style={styles.sendButton}
        onPress={handleSendMessage}
        disabled={!userMessage || isBotProcessing}
      >
        <Ionicons name="md-send" size={21} color="blue" />
      </TouchableOpacity>
    ) : null
  )}
</View>

    </KeyboardAvoidingView>
  );
};


const Tab = createBottomTabNavigator();



export default function App() {
  const [chatCount, setChatCount] = useState(1);
  const [isNightMode, setIsNightMode] = useState(true);
  const [vibrationEnabled, setVibrationEnabled] = useState(true);
  const [initialMessage, setInitialMessage] = useState("");
  const [isLanguageModalVisible, setIsLanguageModalVisible] = useState(false);
  const [deletedChats, setDeletedChats] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState(deviceLanguage);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [messageHistory, setMessageHistory] = useState({



    1: [{ role: 'assistant', content: initialGreeting }]
  });
  const [isQuestionModalVisible, setIsQuestionModalVisible] = useState(false);
  const [openBlockIndex, setOpenBlockIndex] = useState(null);
  const [predefinedQuestionBlocks, setPredefinedQuestionBlocks] = useState([
    {
      title: currentTranslations.blocks.health,
      questions: currentTranslations.questions.slice(0, 2), // Cambia esto a slice(0, 6)
      imageUrl: 'https://app.hundezonen.ch/docs/vvedt.png'
    },
    {
      title: currentTranslations.blocks.training,
      questions: currentTranslations.questions.slice(2, 4), // Cambia esto a slice(2, 6)
      imageUrl: 'https://app.hundezonen.ch/docs/vet.png'
    },
    {
      title: currentTranslations.blocks.nutrition,
      questions: currentTranslations.questions.slice(4, 6), // Mantén esto igual
      imageUrl: 'https://app.hundezonen.ch/docs/vedt.png'
    },
    // Nueva sección de preguntas predefinidas
    {
      title: currentTranslations.blocks.recipes,
      questions: currentTranslations.questions.slice(6, 56), // Cambia esto según la nueva sección
      imageUrl: 'https://app.hundezonen.ch/docs/vet.png'
    },
    {
      title: currentTranslations.blocks.travel,
      questions: currentTranslations.questions.slice(56, 58), // Cambia esto según la nueva sección
      imageUrl: 'https://app.hundezonen.ch/docs/vet.png'
    },
  ]);

  const handleResponseModeChange = (newMode) => {
    setResponseMode(newMode);
    const updatedGreeting = greetings[selectedLanguage][newMode] || greetings['en'][newMode];
    
    // Actualizar el historial de mensajes para el chat 1 con el nuevo saludo
    setMessageHistory((prevHistory) => {
      return {
        ...prevHistory,
        1: [{ role: 'assistant', content: updatedGreeting }]
      };
    });
  };



  const [responseMode, setResponseMode] = useState("general");
  const EmptyComponent = () => null;

  
  const handleLanguageSelection = async (lang) => {
    setSelectedLanguage(lang);
    setIsLanguageModalVisible(false);
  
    const updatedTranslations = translations[lang] || translations.en;
    const updatedGreeting = greetings[lang][responseMode] || greetings['en'][responseMode];
  
    // Actualizar historial de mensajes y mensaje inicial para todos los chats
    setMessageHistory((prevHistory) => {
      const updatedHistory = { ...prevHistory };
      Object.keys(updatedHistory).forEach((chatId) => {
        updatedHistory[chatId][0].content = updatedGreeting;
      });
      return updatedHistory;
    });
  
    // Restablecer historial de mensajes para el chat 1 con el saludo inicial
    setMessageHistory((prevHistory) => {
      return {
        ...prevHistory,
        1: [{ role: 'assistant', content: updatedGreeting }]
      };
    });
    
    // Actualizar bloques predefinidos de preguntas
    const updatedQuestions = updatedTranslations.questions;
    setPredefinedQuestionBlocks([
      {
        title: updatedTranslations.blocks.health,
        questions: updatedQuestions.slice(0, 2),
        imageUrl: 'https://app.hundezonen.ch/docs/vvedt.png'
      },
      {
        title: updatedTranslations.blocks.training,
        questions: updatedQuestions.slice(2, 4),
        imageUrl: 'https://app.hundezonen.ch/docs/vet.png'
      },
      {
        title: updatedTranslations.blocks.nutrition,
        questions: updatedQuestions.slice(4, 6),
        imageUrl: 'https://app.hundezonen.ch/docs/vedt.png'
      },
      // Actualizar preguntas para las nuevas secciones
      {
        title: updatedTranslations.blocks.recipes,
        questions: updatedQuestions.slice(6, 56),
        imageUrl: 'https://app.hundezonen.ch/docs/vet.png'
      },
      {
        title: updatedTranslations.blocks.travel,
        questions: updatedQuestions.slice(56, 58),
        imageUrl: 'https://app.hundezonen.ch/docs/vet.png'
      },
    ]);
  
        // Actualizar el mensaje inicial para todos los chats
        setInitialMessage(updatedGreeting);
        setInitialMessage("");  

  };
  
  const handleQuestionIconPress = () => {
    setOpenBlockIndex(null);
    setIsQuestionModalVisible(true);
  };

  const handlePredefinedQuestionPress = (question) => {
    setIsQuestionModalVisible(false);
    setInitialMessage(question);
  };

  const createNewChat = () => {
    if (chatCount < 4) {
      setChatCount(prev => prev + 1);
      const updatedGreeting = greetings[selectedLanguage][responseMode] || greetings['en'][responseMode];
  
      // Restablecer el historial de chat para el nuevo chat
      setMessageHistory(prevHistory => ({
        ...prevHistory,
        [chatCount + 1]: [{ role: 'assistant', content: updatedGreeting }]
      }));
    }
  };

  
  const SettingsScreenWrapper = (props) => (
    <SettingsScreen
      {...props}
      setChatCount={setChatCount}
      isNightMode={isNightMode}
      onNightModeToggle={() => setIsNightMode(!isNightMode)}
      vibrationEnabled={vibrationEnabled}
      onVibrationToggle={() => setVibrationEnabled(!vibrationEnabled)}
      onCreateNewChat={createNewChat}
      showLanguageModal={() => setIsLanguageModalVisible(true)}
      onResponseModeChange={handleResponseModeChange}
      showQuestionModal={() => openImageModal()} // Aquí abrimos el modal
      showHistoryModal={() => setIsHistoryModalVisible(true)}
      currentLanguage={selectedLanguage} // Pasa el estado selectedLanguage aquí
    
    />
  );

  const ChatBotComponentWrapper = (props) => {
    const chatId = props.route.params.chatId;
    const chatMessageHistory = messageHistory[chatId] || [];
    
    return (
      <ChatBotComponent
        {...props}
        isNightMode={isNightMode}
        vibrationEnabled={vibrationEnabled}
        setMessageHistory={setMessageHistory}
        messageHistory={chatMessageHistory}
        initialUserMessage={initialMessage}
        clearInitialMessage={() => setInitialMessage('')}
        responseMode={responseMode}  // Pasa el estado responseMode aquí
        handleSaveConversation={handleSaveConversation}
        selectedLanguage={selectedLanguage}
        handleQuestionIconPress={handleQuestionIconPress} 
       
      />
    );
  };



  const [isHistoryModalVisible, setIsHistoryModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");


  const toggleHistoryModal = () => {
    setIsHistoryModalVisible(!isHistoryModalVisible);
  };
  const handleChatFromHistory = (chatId, conversationIndex) => {
    const chatToRecover = deletedChats[chatId][conversationIndex];
  
    // Mover chat de deletedChats a messageHistory, asignándolo siempre al chat 1
    setMessageHistory(prev => ({
      ...prev,
      [1]: chatToRecover
    }));
  
    // Actualizar el array de conversaciones eliminadas en deletedChats
    setDeletedChats(prev => {
      const updatedDeleted = { ...prev };
      updatedDeleted[chatId].splice(conversationIndex, 1);  // Elimina la conversación específica del historial
      if(updatedDeleted[chatId].length === 0) {
        delete updatedDeleted[chatId];  // Si no quedan conversaciones, elimina el chatId
      }

      // Almacenar en AsyncStorage después de actualizar el estado
      storeDeletedChats(updatedDeleted);

      return updatedDeleted;
    });
  
    // Cierra el modal de historial
    toggleHistoryModal();
};

const handleDeleteFromHistory = (chatId, conversationIndex) => {
  setDeletedChats(prev => {
    const updatedDeleted = { ...prev };
    updatedDeleted[chatId].splice(conversationIndex, 1);  // Elimina la conversación específica del historial
    if(updatedDeleted[chatId].length === 0) {
      delete updatedDeleted[chatId];  // Si no quedan conversaciones, elimina el chatId
    }

    // Almacenar en AsyncStorage después de actualizar el estado
    storeDeletedChats(updatedDeleted);

    return updatedDeleted;
  });
};
const storeDeletedChats = async (deleted) => {
  try {
    console.log("Storing deleted chats:", deleted);
    await AsyncStorage.setItem('deletedChats', JSON.stringify(deleted));
  } catch (error) {
    console.error("Error storing deleted chats:", error);
  }
};

const retrieveDeletedChats = async () => {
  try {
    const storedDeletedChats = await AsyncStorage.getItem('deletedChats');
    console.log("Retrieved deleted chats:", storedDeletedChats);
    if (storedDeletedChats) {
      return JSON.parse(storedDeletedChats);
    }
    return null;
  } catch (error) {
    console.error("Error retrieving deleted chats:", error);
    return null;
  }
};


const handleSaveConversation = () => {
  const currentChatId = 1; // Cambia esto según tu lógica para obtener el chat actual
  const currentChatHistory = messageHistory[currentChatId];

  if (currentChatHistory && currentChatHistory.length > 1) {
    setDeletedChats(prev => {
      const updatedDeletedChats = {
        ...prev,
        [currentChatId]: prev[currentChatId] ? [...prev[currentChatId], currentChatHistory] : [currentChatHistory]
      };

      // Almacenar en AsyncStorage después de actualizar el estado
      storeDeletedChats(updatedDeletedChats);

      // Establecer el mensaje para el modal
      setModalMessage(historyTranslations[selectedLanguage].savedConversation);
      
      setIsModalVisible(true);
      setTimeout(() => {
        setIsModalVisible(false);
      }, 2000);

      return updatedDeletedChats;
    });
  } else {
    // No hay conversaciones para guardar, establecer el mensaje adecuado
    setModalMessage(historyTranslations[selectedLanguage].noConversations);
    setIsModalVisible(true);
    setTimeout(() => {
      setIsModalVisible(false);
    }, 2000);
  }
};


useEffect(() => {
  const loadStoredDeletedChats = async () => {
    console.log("Loading stored deleted chats...");
    const storedDeleted = await retrieveDeletedChats();
    if (storedDeleted) {
      console.log("Setting deleted chats to state:", storedDeleted);
      setDeletedChats(storedDeleted);
    }
  };

  loadStoredDeletedChats();
}, []);


  return (
    
    <NavigationContainer>
      <>
      <Modal
  animationType="slide"
  transparent={true}
  visible={isQuestionModalVisible}
  onRequestClose={() => {
  setIsQuestionModalVisible(false);
  }}
>
  <ImageBackground
    source={backgroundImage}
    style={{ flex: 1, resizeMode: 'cover' }}
  >
    <View style={[styles.modalView, { backgroundColor: isNightMode ? 'rgba(51, 51, 51, 0.7)' : 'rgba(210, 210, 210, 0.7)' }]}>
      <TouchableOpacity
        onPress={() => setIsQuestionModalVisible(false)}
        style={[styles.closeButton, { top: 50, right: 20, position: 'absolute', zIndex: 1 }]}
      >
        <Ionicons
          name="arrow-down-circle-outline"
          size={28}
          color={isNightMode ? '#c3c3c3' : '#074747a6'}
        />
      </TouchableOpacity>

      <ScrollView>
        {predefinedQuestionBlocks.map((block, blockIndex) => (
          <TouchableOpacity
            key={blockIndex}
            onPress={() => {
              if (openBlockIndex === blockIndex) {
                setOpenBlockIndex(null);
              } else {
                setOpenBlockIndex(blockIndex);
              }
            }}
            style={[
              styles.blockContainer,
              {
                marginTop: blockIndex === 0 ? 100 : 10,
                borderRadius: 10,
                padding: 10,
                backgroundColor: isNightMode ? '#444' : '#015353',
                marginBottom: 20,
              },
              openBlockIndex === blockIndex && { backgroundColor: isNightMode ? '#444' : '#ddd' },
            ]}
          >
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                backgroundColor: isNightMode ? 'rgba(0, 0, 0, 0.7)' : 'rgba(255, 255, 255, 0.7)',
                borderRadius: 10,
                padding: 10,
              }}
            >
              {/* Iconos */}
              {blockIndex === 0 && <AntDesign name="hearto" size={24} color={isNightMode ? 'white' : '#074747'} style={{ marginRight: 10 }} />}
              {blockIndex === 1 && <MaterialIcons name="sports-baseball" size={24} color={isNightMode ? 'white' : '#074747'} style={{ marginRight: 10 }} />}
              {blockIndex === 2 && <Fontisto name="doctor" size={24} color={isNightMode ? 'white' : '#074747'} style={{ marginRight: 10 }} />}
              {blockIndex === 3 && <FontAwesome5 name="bone" size={24} color={isNightMode ? 'white' : '#074747'} style={{ marginRight: 10 }} />}
              {blockIndex === 4 && <FontAwesome name="paw" size={24} color={isNightMode ? 'white' : '#074747'} style={{ marginRight: 10 }} />}
              
              {/* Título */}
              <Text style={[styles.blockTitle, { color: isNightMode ? 'white' : '#074747', textAlign: 'center' }]}>
                {block.title}
              </Text>
            </View>

            {openBlockIndex === blockIndex && block.questions.map((question, questionIndex) => (
              <TouchableOpacity
                key={questionIndex}
                onPress={() => handlePredefinedQuestionPress(question)}
                style={[
                  styles.questionItem,
                  { backgroundColor: isNightMode ? '#444' : '#ddd', borderRadius: 10, padding: 10, marginBottom: 10, textAlign: 'center' },
                ]}
              >
                <Text style={[styles.modalText, { color: isNightMode ? '#fff' : '#333' }]}>
                  {question}
                </Text>
              </TouchableOpacity>
            ))}
          </TouchableOpacity>
        ))}
      </ScrollView>
    </View>
  </ImageBackground>
</Modal>

<Modal
  animationType="slide"
  transparent={true}
  visible={isModalVisible}
  onRequestClose={() => {
    setIsModalVisible(false);
  }}
>
  <View style={styles.saveConversationCenteredView}>
    <View style={[
      styles.saveConversationModalView, 
      isNightMode ? styles.saveConversationModalViewDark : {}
    ]}>
      <Text style={[
        styles.saveConversationModalText, 
        isNightMode ? styles.saveConversationModalTextDark : {}
      ]}>
        {modalMessage}
      </Text>
    </View>
  </View>
</Modal>


<Modal
  animationType="slide"
  transparent={true}
  visible={isLanguageModalVisible}
  onRequestClose={() => {
    setIsLanguageModalVisible(false);
  }}
>
  <View style={[styles.modalView, styles.languageModal]} id="languageModal">
    <TouchableOpacity
      onPress={() => setIsLanguageModalVisible(false)}
      style={styles.closeButton}
    >
      <Text style={styles.closeButtonText}>X</Text>
    </TouchableOpacity>

    {Object.keys(languageDescriptions).map((lang) => (
      <TouchableOpacity
        key={lang}
        onPress={() => handleLanguageSelection(lang)}
      >
        <Text style={styles.modalText}>{languageDescriptions[lang]}</Text>
      </TouchableOpacity>
    ))}
  </View>
</Modal>

<Modal
  animationType="slide"
  transparent={true}
  visible={isHistoryModalVisible}
  onRequestClose={() => {
    setIsHistoryModalVisible(false);
  }}
>
  <View style={[styles.modalView, isNightMode && styles.modalViewDark]}>
    <TouchableOpacity
      onPress={() => setIsHistoryModalVisible(false)}
      style={[styles.closeButton, isNightMode && styles.closeButtonDark, { top: 60, right: 20 }]}
    >
      <Ionicons
        name="arrow-down-circle-outline"
        size={28}
        color={isNightMode ? 'white' : '#009688'}
      />
    </TouchableOpacity>

    <View style={{ marginTop: 100, maxWidth: '90%' }}>
      <ScrollView style={{ flex: 1 }}>
        {deletedChats && Object.entries(deletedChats).length > 0 ? (
          Object.entries(deletedChats).map(([chatId, chatDataList]) => (
            chatDataList.map((chatData, index) => (
              <TouchableOpacity 
                key={chatId + "-" + index}
                onPress={() => handleChatFromHistory(chatId, index)}
                style={[styles.chatItem, isNightMode && styles.chatItemDark]}
              >
                <Text style={[styles.chatText, isNightMode && styles.chatTextDark, { paddingRight: 30 }]}>
                  Chat {chatId} - {index + 1}: {chatData && chatData[1]?.content}
                </Text>

                {/* Botón para eliminar el chat del historial */}
                <TouchableOpacity 
                  onPress={() => handleDeleteFromHistory(chatId, index)}
                  style={{ position: 'absolute', right: 0, top: '40%', transform: [{ translateY: -12 }] }}
                  pointerEvents='none'
                >
                  <Ionicons 
                    name="close-circle-outline" 
                    size={21} 
                    color={isNightMode ? '#a6a6a6' : '#a6a6a6'}
                  />
                </TouchableOpacity>
                
              </TouchableOpacity>
            ))
          ))
        ) : (
          <Text style={{ 
            textAlign: 'center', 
            marginTop: 20, 
            padding: 10, 
            color: isNightMode ? 'white' : 'black'  // Cambia el color del texto según el modo
          }}>
            {historyTranslations[selectedLanguage].noConversations}
          </Text>          
        )}
      </ScrollView>
    </View>
  </View>
</Modal>


<Modal
  animationType="slide"
  transparent={true}
  visible={isHistoryModalVisible}
  onRequestClose={() => {
    setIsHistoryModalVisible(false);
  }}
>
  <View style={styles.modalView}>
    <TouchableOpacity
      onPress={() => setIsHistoryModalVisible(false)}
      style={styles.closeButton}
    >
      <Text style={styles.closeButtonText}>X</Text>
    </TouchableOpacity>

    {deletedChats && Object.entries(deletedChats).map(([chatId, chatData]) => (
      <TouchableOpacity key={chatId} onPress={() => handleChatFromHistory(chatId)}>
        <Text style={styles.modalText}>
          Chat {chatId}: {chatData && chatData[chatData.length - 1]?.content}
        </Text>
      </TouchableOpacity>
    ))}
  </View>
</Modal>

<Tab.Navigator

  screenOptions={({ route, navigation }) => ({
    headerShown: false,
    tabBarIcon: ({ color, size, focused }) => {
      let iconName;
      const rotateValue = new Animated.Value(0);
      const scaleValue = new Animated.Value(1);
      const rotate = rotateValue.interpolate({
        inputRange: [0, 1],
        outputRange: ['0deg', '30deg']
      });
      const scale = scaleValue.interpolate({
        inputRange: [0, 1],
        outputRange: [0.8, 1.2]
      });

      if (focused) {
        Animated.timing(rotateValue, {
          toValue: 1,
          duration: 200,
          easing: Easing.elastic(2),
          useNativeDriver: true,
        }).start();
      } else {
        Animated.timing(rotateValue, {
          toValue: 0,
          duration: 150,
          useNativeDriver: true,
        }).start();
      }
      if (route.name === 'Chat1') {
        iconName = 'ios-chatbubble-ellipses-outline';
        return (
          <Animated.View style={{ transform: [{ rotate }] }}>
            <Ionicons  name={iconName} size={28} color={color} />
          </Animated.View>
        );
      } else if (route.name.startsWith('Chat')) {
        iconName = 'chatbubbles-outline';
        return (
          <Animated.View style={{ transform: [{ rotate }] }}>
            <Ionicons  name={iconName} size={29} color={color} />
          </Animated.View>
        );
      
    } else if (route.name === 'Settings') {
        iconName = 'bars';
    
        return (
          <Animated.View style={{ transform: [{ rotate }] }}>
            <AntDesign name={iconName} size={size + 6} color={color} />
          </Animated.View>
        );
    }

    },
    tabBarShowLabel: false,  // Agregar esta línea
    tabBarActiveTintColor: '#07a596',
    tabBarInactiveTintColor: 'gray',
    tabBarStyle: {
      display: 'flex',
      backgroundColor: isNightMode ? '#333' : '#fff',
    },
  })}
>

{Array.from({ length: chatCount }).map((_, index) => (
  <Tab.Screen
    key={index}
    name={`Chat${index + 1}`}
    component={ChatBotComponentWrapper}
    initialParams={{ chatId: index + 1, chatCount, setChatCount }}
    options={({ navigation }) => ({
      headerTitle: () => (
        <View style={styles.titleContainer}>
          <Image source={{ uri: 'https://app.hundezonen.ch/docs/peerrdddo.png' }} style={styles.image} />
          <TypingText style={styles.title} text="Hundezonen Schweiz " />
        </View>
      ),
      headerTitleAlign: 'left',
      headerStyle: {
        backgroundColor: isNightMode ? '#333' : '#fff',
      },
      headerTintColor: isNightMode ? '#fff' : '#009688',
      headerRight: () => (
        <View style={[styles.headerRight, { justifyContent: 'space-between', flexDirection: 'row' }]}>
        </View>
      ),
    })}
  />
))}


<Tab.Screen
  name="Settings"
  component={SettingsScreenWrapper}
  initialParams={{
    onResponseModeChange: handleResponseModeChange,
    // ... otros parámetros iniciales ...
  }}
  options={{
    tabBarLabel: 'More', // Etiqueta en el ícono de abajo
    headerTitle: '', // Cambia el título en el encabezado
    headerStyle: {
      backgroundColor: isNightMode ? '#333' : '#fff',
    },
    headerTintColor: isNightMode ? '#009688' : '#009688',
  }}
/>

</Tab.Navigator>

</>
</NavigationContainer>
  ); 
  }

  const styles = StyleSheet.create({

    container: {
      flex: 1,
      backgroundColor: 'black',

    },

    chatContainer: {
      flex: 1,
      padding: 16,
      marginTop: 0,
      marginBottom: -5,
      
      
    },
    userMessageContainer: {
      alignItems: 'flex-start',
      marginBottom: 16,
      borderRadius: 16,
      backgroundColor: '#E0FFE0', // Verde claro para mensajes de usuario
      padding: 12,
      alignSelf: 'flex-start', // Alinea el contenedor a la izquierda
      maxWidth: '90%', // Opcional: Limita el ancho máximo si lo deseas
    },
    gptMessageContainer: {
      alignItems: 'flex-start',
      marginBottom: 16,
      borderRadius: 16,
      backgroundColor: '#3E8AFF', // Azul para mensajes de GPT
      padding: 12,
      alignSelf: 'flex-start', // Alinea el contenedor a la izquierda
      maxWidth: '90%', // Opcional: Limita el ancho máximo si lo deseas
    
    },
    userMessage: {
      textAlign: 'left',
      color: '#333',
      fontSize: 16,
      fontFamily: 'Arial', // Cambia a una fuente moderna y legible
    },
    gptMessage: {
      textAlign: 'left',
      color: 'white',
      fontSize: 16,
  
    },
    input: {
      borderWidth: 0,
      borderColor: '#e6e6e6',
      padding: 10,
      marginVertical: 0,
      borderRadius: 20,
      backgroundColor: 'white',
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.2,
      shadowRadius: 4,
      elevation: 1,
      fontSize: 18,
      
      color: '#333',
      marginBottom: 10,
      height:50

    },
    buttonPlus: {
      position: 'absolute',
      top: 30,
      left: 15,
      backgroundColor: '#3E8AFF',
      padding: 12,
      borderRadius: 30,
      zIndex: 1000,
      shadowColor: '#ffebcd00',
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.4,
      shadowRadius: 4,
      elevation: 6,
    },
    closeButton: {
      position: 'absolute',
      top: 10,
      right: 15,
      backgroundColor: 'transparent',
      padding: 12,
      borderRadius: 30,
      zIndex: 1000,
    },
    textBold: {
      fontFamily: 'Arial',
      fontWeight: 'bold',
    },
    text: {
      color: '#333',
      letterSpacing: 0.5,
      fontFamily: 'Arial',
      fontSize: 16,
    },
    nightModeContainer: {
      backgroundColor: '#333',
    },
    flexStyle: {
      flex: 1,
    },
    nightModeFlexStyle: {
      backgroundColor: '#333',
    },
    sendButton: {
      width: 40,
      height: 40,
      borderRadius: 20,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#07a596',
      marginBottom: 25,
   

  
    },
    loader: {
      marginLeft: 10, // Ajusta el margen izquierdo según tu diseño
    },
    modalView: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#009688', // Cambiando a un fondo semitransparente negro
      padding: 20
    },
  
    
    nightModeBackground: {
      backgroundColor: '#333',
    },
    
    closeButton: {
      position: 'absolute',
      top: 550,
      right: 10,
      padding: 10,
      borderRadius: 5,
      backgroundColor: '#00000045',
      zIndex: 1 // Esto asegurará que el botón esté siempre en la parte superior
    },
    closeButtonText: {
      color: 'white',
      fontWeight: 'bold',
      fontSize: 18
    },
    blockContainer: {
      width: '100%',
      padding: 10,
      marginBottom: 20,
      backgroundColor: 'rgba(255,255,255,0.1)', // Dando un ligero fondo para contrastar con los títulos y preguntas
      borderRadius: 10
    },
    blockTitle: {
      fontSize: 24,  // Aumentando el tamaño de fuente
      color: 'white',  // Cambiando a color blanco
      fontWeight: 'bold',
      marginBottom: 10
    },
    modalText: {
      fontSize: 16,
      padding: 10,
      marginVertical: 5, // Esto agrega espacio vertical entre preguntas
      borderWidth: 1,    // Esto agrega un borde delgado
      borderColor: '#66339900', // Color del borde, elige el que prefieras
      borderRadius: 10,   // Redondeo de bordes
      backgroundColor: '#0000002b', // Color de fondo, elige el que prefieras
      color: 'white',  // Cambiando a color blanco
    },
    titleContainer: {
      flexDirection: 'row', // Alinea los elementos en una fila
      alignItems: 'center', // Centra los elementos verticalmente
      marginLeft: 10, // Agrega un margen izquierdo para separar la imagen del título
    },
    title: {
      fontSize: 18, // Tamaño de fuente para el título
      marginLeft: 10, // Margen izquierdo para separar el título de la imagen
      color: '#009688', // Color verde
    },
    image: {
      width: 20, // Ancho de la imagen
      height: 20, // Alto de la imagen
    },
    helpIcon: {
      marginRight: 10,
    }, 
    languageModal: {
      width: '80%',
      backgroundColor: '#555555c4',
      borderRadius: 15,
      padding: 20,
      alignItems: 'center',
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      elevation: 5,
    },
    closeButton: {
      position: 'absolute',
      top: 40,
      right: 10,
      backgroundColor: 'transparent',
    },
    closeButtonText: {
      fontSize: 25,
      fontWeight: 'bold',
      color: '#dddddd8c',
    },
    modalText: {
      fontSize: 17,
      marginBottom: 15,
      color: '#8effff',
  
    },
    modalButton: {
      width: '100%',
      padding: 10,
      borderRadius: 5,
      alignItems: 'center',
      backgroundColor: '#007BFF',
      marginTop: 10,
    },
    modalButtonText: {
      color: 'white',
      fontSize: 17,
      fontWeight: 'bold',
      color: '#8effff',
    },
    blockContainer: {
      padding: 10, // Ajusta el valor según tus preferencias
      borderRadius: 5,
      marginBottom: 10,
      elevation: 2,
    },
      modalView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f0f0f0',
      },
      modalViewDark: {
        backgroundColor: '#333',
      },
      closeButton: {
        position: 'absolute',
        top: 60,
        right: 20,
      },
      closeButtonText: {
        fontSize: 20,
        color: 'black',
      },
      closeButtonTextDark: {
        color: 'white',
      },
      chatItem: {
        padding: 10,
        marginVertical: 5,
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 5,
      },
      chatItemDark: {
        borderColor: '#555',
        backgroundColor: '#444',
      },
      chatText: {
        fontWeight: 'bold',
        fontSize: 16,
        marginBottom: 5,
      },
      chatTextDark: {
        color: 'white',
      },
      saveConversationCenteredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        marginTop: 22
      },
      
      saveConversationModalView: {
        margin: 20,
        backgroundColor: "white",
        borderRadius: 20,
        padding: 35,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
          width: 0,
          height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5
      },
      tabBarStyle: {
        borderTopWidth: 0,
        
      },
      tabBarStyle: {
        borderTopWidth: 0,
        borderBottomWidth: 0,
        marginTop: 0,
        marginBottom: 0,
      },
      headerStyle: {
        borderTopWidth: 0,
        borderBottomWidth: 0,
        marginTop: 0,
        marginBottom: 0,
      },
      
    
      saveConversationModalViewDark: {
        backgroundColor: "#2c2c2c", // Fondo oscuro para el modo nocturno
        shadowColor: "#888",       // Sombras más claras para el modo nocturno
      },
      saveConversationModalText: {
        marginBottom: 15,
        textAlign: "center",
        color: "black"             // Texto negro por defecto
      },
      botAvatar: {
        width: 30,
        height: 30,
        borderRadius: 0,
        marginRight: 10
      },
      userAvatar: {
        width: 40,
        height: 40,
        borderRadius: 20,
        marginRight: 10,
      },
      
      saveConversationModalTextDark: {
        color: "white"             // Texto blanco para el modo nocturno
      },helpIcon: {
        flexDirection: 'row',         // Alineación horizontal de elementos
        alignItems: 'center',         // Centra los elementos verticalmente
        justifyContent: 'flex-end',  // Alinea los elementos a la derecha
        marginRight: 20,              // Distancia de 20 unidades del borde derecho
      },
      helpIcon: {
        flexDirection: 'row',         // Alineación horizontal de elementos
        alignItems: 'center',         // Centra los elementos verticalmente
        justifyContent: 'flex-end',  // Alinea los elementos a la derecha
        marginBottom:10             // Distancia de 20 unidades del borde derecho
      },
  
      
    });
