import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Image,  } from 'react-native';
import * as Localization from 'expo-localization';
import { FontAwesome } from '@expo/vector-icons';



const translations = {
  en: {
    title: "Welcome to AI Dog-Chat ",
    description: "Subscribe to enjoy all the features.",
    button: "Subscribe  ",
    restore: "Restore Purchas "
  },
  es: {
    title: "Bienvenido a AI Dog-Chat ",
    description: "Suscríbete para disfrutar de todas las características.",
    button: "Suscríbete  ",
    restore: "Restaurar Compra "
  },
  de: {
    title: "Willkommen in AI Dog-Chat ",
    description: "Abonnieren Sie, um alle Funktionen zu genießen.",
    button: "Abonnieren  ",
    restore: "Kauf wiederherstellen "
  },
  fr: {
    title: "Bienvenue dans AI Dog-Chat ",
    description: "Abonnez-vous pour profiter de toutes les fonctionnalités.",
    button: "S'abonner  ",
    restore: "Restaurer la chat  "
  },
  it: {
    title: "Benvenuto nella AI Dog-Chat ",
    description: "Iscriviti per godere di tutte le funzionalità.",
    button: "Iscriviti  ",
    restore: "Ripristina acquisto"
  },
  tr: {
    title: "Uygulamamıza AI Dog-Chat ",
    description: "Tüm özelliklerden yararlanmak için abone olun.",
    button: "Abone Ol  ",
    restore: "Satın almayı Geri Yükle"
  }
};

const onRestore = () => {
    alert('Restoring purchases...');  // Just a placeholder. Implement the real logic here.
};


const SubscriptionScreen = ({ onSubscribe, isDarkMode  }) => {
  const [typedTitle, setTypedTitle] = useState('');
  const [typedDescription, setTypedDescription] = useState('');
  const [typedButtonText, setTypedButtonText] = useState('');
  const [typedRestoreText, setTypedRestoreText] = useState('');


  useEffect(() => {
    const currentLanguage = Localization.locale.split('-')[0]; // "en-US" would become "en"
    const languageContent = translations[currentLanguage] || translations.en;

    let currentText = '';
    let currentIndex = 0;

    // Typing effect for title
    const typingInterval = setInterval(() => {
      if (currentIndex <= languageContent.title.length) {
        currentText = languageContent.title.slice(0, currentIndex);
        setTypedTitle(currentText);
        currentIndex++;
      } else {
        clearInterval(typingInterval);
      }
    }, 100);

    // Reset for description
    currentIndex = 0;
    const descriptionTypingInterval = setInterval(() => {
      if (currentIndex <= languageContent.description.length) {
        currentText = languageContent.description.slice(0, currentIndex);
        setTypedDescription(currentText);
        currentIndex++;
      } else {
        clearInterval(descriptionTypingInterval);
      }
    }, 100);

    // Reset for button text
    currentIndex = 0;
    const buttonTypingInterval = setInterval(() => {
      if (currentIndex <= languageContent.button.length) {
        currentText = languageContent.button.slice(0, currentIndex);
        setTypedButtonText(currentText);
        currentIndex++;
      } else {
        clearInterval(buttonTypingInterval);
      }
    }, 100);


 // Reset for restore text
    currentIndex = 0;
    const restoreTypingInterval = setInterval(() => {
      if (currentIndex <= languageContent.restore.length) {
        currentText = languageContent.restore.slice(0, currentIndex);
        setTypedRestoreText(currentText);
        currentIndex++;
      } else {
        clearInterval(restoreTypingInterval);
      }
    }, 100);

}, []);


return (
  <View style={[styles.container, isDarkMode && styles.darkModeContainer]}>
    <Image source={{ uri: 'https://app.hundezonen.ch/docs/huella%20copia.png' }} style={styles.image} />
    <Text style={[styles.title, isDarkMode && styles.darkModeTitle]}>{typedTitle}</Text>
    <Text style={[styles.subtitle, isDarkMode && styles.darkModeSubtitle]}>{typedDescription}</Text>
    <TouchableOpacity
      onPress={onSubscribe}
      style={[styles.buttonContainer, isDarkMode && styles.darkModeButtonContainer]}
      activeOpacity={0.8}
    >
      <FontAwesome name="rocket" size={24} color="#18c9b7" style={{ marginRight: 5 }} />
      <Text style={[styles.buttonText, isDarkMode && styles.darkModeButtonText]}>{typedButtonText}</Text>
    </TouchableOpacity>

    <TouchableOpacity
      onPress={onRestore}
      style={[styles.restoreButtonContainer, isDarkMode && styles.darkModeRestoreButtonContainer]}
      activeOpacity={0.8}
    >
      <Text style={[styles.elegantText, isDarkMode && styles.darkModeElegantText]}>{typedRestoreText}</Text>
    </TouchableOpacity>
  </View>
);
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    backgroundColor: 'black', // Adjust the button color
  },
  restoreButtonContainer: {
     marginTop: 30,
    },
    elegantText: {
        fontWeight: '300', // este es un peso ligero, si estás en iOS puedes intentar con '100' también.
        fontSize: 18, // ajusta el tamaño según prefieras
        color: 'white'  // un color gris oscuro, puedes ajustarlo a tus preferencias.
    },

  image: {
    width: 200,
    height: 200,
    marginBottom: 20,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 10,
    color: '#cc75db',
  },
  subtitle: {
    fontSize: 18,
    textAlign: 'center',
    marginBottom: 30,
    color: '#b2b2b2',
  },
  buttonText: {
    color: '#18c9b7', // Adjust the button text color
    fontSize: 18,
    fontWeight: 'bold',
  },
  buttonContainer: {
    flexDirection: 'row', // Para que el ícono esté al inicio. Si deseas debajo, cambia a 'column'.
    alignItems: 'center', // Para centrar el ícono y el texto verticalmente
    backgroundColor: '#3b3b3b', // Adjust the button color
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 10,
    elevation: 3
},
darkModeContainer: {
  backgroundColor: 'black',
},
darkModeTitle: {
  color: 'white',
},
darkModeSubtitle: {
  color: '#b2b2b2',
},
darkModeButtonContainer: {
  backgroundColor: '#3b3b3b',
},
darkModeButtonText: {
  color: '#18c9b7',
},
darkModeRestoreButtonContainer: {
  // Estilos para el modo oscuro del botón de restaurar
},
darkModeElegantText: {
  // Estilos para el texto elegante en modo oscuro
},
});

export default SubscriptionScreen;
