import React, { useState, useRef, useEffect } from 'react';
import { View, Text, TextInput, Button, Image, ScrollView, Keyboard, TouchableOpacity, Modal, } from 'react-native';
import axios from 'axios';
import * as Progress from 'react-native-progress';
import * as Sharing from 'expo-sharing';
import * as FileSystem from 'expo-file-system';
import * as MediaLibrary from 'expo-media-library';
import { Share } from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome'; // Ejemplo utilizando FontAwesome
import { Platform } from 'react-native';

  const DogBreedModal = ({ isVisible, onClose, selectedLanguage, isNightMode }) => {
  const [breed, setBreed] = useState('');
  const [inputText, setInputText] = useState('');
  const [imageUrl, setImageUrl] = useState(null);
  const [chatLog, setChatLog] = useState([]);
  const scrollViewRef = useRef(null);
  const [isModalVisible, setModalVisible] = useState(true);
  const [isChatExpanded, setIsChatExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pendingMessage, setPendingMessage] = useState(null);
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [showShareButton, setShowShareButton] = useState(false);
  const [progress, setProgress] = useState(0);


  const dogBreeds = [
    "Labrador", "Golden Retriever", "Dálmata", "Husky", "Beagle",
    "Poodle", "Rottweiler", "Boxer", "Doberman", "Pitbull",
    "Akita", "Bulldog", "Chihuahua", "Dachshund", "Great Dane",
    "Greyhound", "Maltese", "Mastiff", "Pomeranian", "Pug",
    "Saint Bernard", "Samoyed", "Shih Tzu", "Siberian Husky", "Spaniel",
    "Terrier", "Yorkshire", "Whippet", "Vizsla", "Tervuren",
    "Setter", "Schnauzer", "Saluki", "Rhodesian Ridgeback", "Pointer",
    "Papillon", "Otterhound", "Newfoundland", "Neapolitan Mastiff", "Dogo Argentino",
    "Collie", "Clumber Spaniel", "Cocker Spaniel", "Chow Chow", "Cane Corso",
    "Briard", "Borzoi", "Bolognese dog", "Bloodhound", "Bichon Frise",
  ];

 const handleBreedSelect = (selectedBreed) => {
  console.log("Raza seleccionada:", selectedBreed);
  setBreed(selectedBreed);

  // Esto hará que el ScrollView se desplace hacia arriba
  scrollViewRef.current?.scrollTo({ y: 0, animated: true });
};

useEffect(() => {
  if (breed) {
      scrollViewRef.current?.scrollTo({ y: 0, animated: true });
  }
}, [breed]);


  const placeholdersi = {
    en: "What dog breed do you want to know about?",
    es: "¿Qué raza de perro quieres conocer?",
    de: "Welche Hunderasse möchtest du erfahren?",
    fr: "Quelle race de chien souhaitez-vous connaître?",
    it: "Di quale razza di cane vuoi saperne di più?",
    tr: "Hangi köpek ırkını öğrenmek istersiniz?"
  };
  
  
  const placeholders = {
    en: "Enter dog breed:",
    es: "Ingrese la raza del perro:",
    de: "Geben Sie die Hunderasse ein:",
    fr: "Entrez la race de chien:",
    it: "Inserisci la razza del cane:",
    tr: "Köpek ırkını girin:"
  };
  

  const generateImageTranslations = {
    en: "Discover",
    es: "Descubrir",
    de: "Entdecken",
    fr: "Découvrir",
    it: "Scopri",
    tr: "Keşfet"
  };
  

  
  const messagePlaceholderTranslations = {
    en: "Type your message to the bot",
    es: "Escribe tu mensaje al bot",
    de: "Schreiben Sie Ihre Nachricht an den Bot",
    fr: "Tapez votre message au bot",
    it: "Scrivi il tuo messaggio al bot",
    tr: "Bota mesajınızı yazın"
  };
  
  const sendTranslations = {
    en: "Send",
    es: "Enviar",
    de: "Senden",
    fr: "Envoyer",
    it: "Invia",
    tr: "Gönder"
  };
  
  const greetingsTranslations = {
    en: 'I am generating a description specifically for you of the dog breed you want to learn about...',
    es: 'Estoy generando una descripción expresamente para ti de la raza de perro que quieres conocer...',
    de: 'Ich erstelle eine Beschreibung speziell für dich von der Hunderasse, über die du mehr erfahren möchtest...',
    fr: 'Je génère une description spécifiquement pour vous de la race de chien que vous souhaitez découvrir...',
    it: 'Sto generando una descrizione appositamente per te della razza di cane che desideri conoscere...',
    tr: 'Senin için öğrenmek istediğin köpek ırkı hakkında özel bir açıklama üretiyorum...'
  };

  const shareButtonTranslations = {
    en: "Share Description",
    es: "Compartir Descripción",
    de: "Beschreibung Teilen",
    fr: "Partager la Description",
    it: "Condividi Descrizione",
    tr: "Açıklamayı Paylaş"
};


const shareImageButtonTranslations = {
  en: "Share Image",
  es: "Compartir Imagen",
  de: "Bild teilen",
  fr: "Partager l'image",
  it: "Condividi immagine",
  tr: "Resmi paylaş"
};


const generatingTranslations = {
  
  imageText:{
    en: "I am generating an image of the dog breed you want to know...",
    es: "Estoy generando una image de la raza de perro que quieres conocer...",
    fr: "Je génère une image  de la race de chien que vous souhaitez connaître...",
    de: "Ich generiere ein Bil der Hunderasse, die Sie kennenlernen möchten...",
    it: "Sto generando un'immagine della razza di cane che vuoi conoscere..."
  },
  

  text: {
      en: "Generating text...",
      es: "Generando texto...",
      fr: "Génération de texte...",
      de: "Text für Sie erzeugen...",
      it: "Generando testo...",
  }
};

  
const currentLanguage = 'en'; // Puedes cambiar este valor dependiendo del idioma del usuario

const greetingMessage = greetingsTranslations[currentLanguage] || greetingsTranslations['en'];

const [loadingMessage, setLoadingMessage] = useState(generatingTranslations.imageText[selectedLanguage]);


  const styles = {
    modalContainer: {
      flex: 1,
      justifyContent: 'center',
      backgroundColor: 'white', // Fondo semitransparente
      padding: 20,
      
    },
    contentContainer: {
      backgroundColor: 'white',
      borderRadius: 10,
      padding: -0,
    },
    inputContainer: {
      marginTop: 20,

    },

    button: {
      backgroundColor: 'blue',
      padding: 10,
      borderRadius: 5,
      alignItems: 'center',
      top: 10
    },
    buttonText: {
      color: 'white',
      fontWeight: 'bold',
    },
   
  
      smallImage: {
        width: '100%',
        height: 100, // tamaño reducido aún más
        resizeMode: 'cover',
        alignSelf: 'center',
        marginBottom: 10, // espacio debajo de la imagen

      },
  
      textInput: {
        padding: 10,
        marginBottom: 10, // espacio debajo del input
        borderRadius: 5,
        width: '100%', // Asegurándonos de que ocupe toda la anchura
        fontSize: 18,
        borderWidth: 1,
        borderColor: 'gray',
        button: 10,
        marginTop: 20
      },
  
      button: {
        backgroundColor: '#e91e63',
        padding: 10,
        borderRadius: 50,
        alignItems: 'center',
        marginBottom: 40, // espacio debajo del botón
        top: 20,

        
      },
  
      scrollView: {
        height: 100, // ejemplo de tamaño original
        padding: 10,
        borderWidth: 1,
        borderColor: 'gray',
        marginBottom: 20, // espacio debajo del chat
        borderRadius: 5,
        width: '100%', // Asegurándonos de que ocupe toda la anchura
        
      },
  
      largeScrollView: {
        height: 250, // tamaño ampliado
        padding: 20,
        borderWidth: 1,
        borderColor: 'gray',
        marginBottom: 20, // espacio debajo del chat
        borderRadius: 5,
        width: '100%', // Asegurándonos de que ocupe toda la anchura
      },

    chatMessage: {
      marginBottom: 5,
    },

    nightModeSection: {
        backgroundColor: '#555',
      },
      nightModeText: {
        color: '#fff',
      },
      nightModeInput: {
        fontSize: 18,
        color: 'white',
        borderWidth: 1,
        borderColor: 'gray',
      },
      nightModeButton: {
        backgroundColor: '#e91e63',
      },
      nightModeButtonText: {
        color: '#fff',
      },
      nightModeScrollView: {
        backgroundColor: '#444',
      },
      nightModeChatMessage: {
        color: '#fff',
      },
      image: {
        width: '100%',
        height: 300,
        borderRadius: 20,
        marginTop: 60,
    },
    smallImage: {
        width: '100%',
        height: 150,
        alignSelf: 'center', // Asegura que la imagen esté centrada en su contenedor padre
        borderRadius: 20, // Radio de borde redondeado
        resizeMode: 'cover', // Asegura que la imagen se adapte manteniendo su relación de aspecto sin recortes
   
    },
    
    scrollView: {
        height: 150, // ejemplo de tamaño original
        padding:10,
        borderRadius:20,

    },
 
      chatMessage: {
        fontSize: 16, // Tamaño de letra aumentado
        marginBottom: 50, // Espaciado entre mensajes
        padding: 10, // Espaciado interno para que se vea más estético
        borderRadius: 30, // Bordes redondeados
        backgroundColor: 'black', // Color de fondo por defecto para todos los mensajes
    },
    
    userMessage: {
        backgroundColor: '#09bbd2', // Un color azul claro para los mensajes del usuario
        alignSelf: 'flex-end', // Alinear a la derecha
        marginRight: 10, // Margen a la derecha para no ocupar todo el ancho
        borderRadius:20
    },
    
    botMessage: {
        backgroundColor: '#e91e6300', // Un color verde claro para los mensajes del bot
        alignSelf: 'flex-start', // Alinear a la izquierda
        marginLeft: 10, // Margen a la izquierda
        borderRadius:20,
        color: '#06786c', // Color de texto en modo noche
        fontSize: 18
    },
    
    largeScrollView: {
        height: 400, // Aumenta la altura
        width: '100%', // Aumenta el ancho
        alignSelf: 'center', // Centrar el contenedor
        borderColor: '#faebd700',
        borderWidth: 1,
        borderRadius: 10,
        padding:10,

    },

    text: {
      fontSize: 24, // Tamaño de fuente grande
      textAlign: 'center', // Texto centrado
      padding: 20, // Padding de 20
      borderRadius: 20, // Radio de 20
      color: 'white', // Color de texto en modo noche
      marginTop: 20,
    },

    textDayMode: {
      color: 'black', // Color de texto en modo día
      backgroundColor: '#0cbfae', // Color de fondo en modo día
      borderRadius:20
    },
    textNightMode: {
      color: 'white', // Color de texto en modo noche
      backgroundColor: '#0cbfae', // Color de fondo en modo noche
      borderRadius:20
    },
    chipContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      paddingVertical: 10,
  },
  chip: {
      borderRadius: 50,
      paddingVertical: 5,
      paddingHorizontal: 15,
      backgroundColor: '#009688',
      margin: 5,
  },
  chipText: {
      fontSize: 16,
      color: 'white',
  },
  marginContainer: {
    marginTop:30,
  },
 
  progressContainer: {
    flex: 1, // Aseguramos que el contenedor ocupe toda la pantalla
    backgroundColor: '#eeeeee63', // Color de fondo, elige el que desees
    justifyContent: 'center', // Centra el contenido verticalmente
    alignItems: 'center', // Centra el contenido horizontalmente
    padding: 5, // Añade un padding al contenedor
    borderRadius: 20,
    marginTop :80
},
greetingText: {
  fontSize: 40,
  color: '#333',
  // ... otros estilos que desees aplicar
},
shareButton: {
  padding: 10,
  borderRadius: 5,
  marginTop:20,   // margen superior
  marginBottom: -50, // margen inferior
  alignItems: 'center',
  justifyContent: 'center',
},
shareButtonText: {
  color: 'gray', // color de texto blanco
  fontSize: 16,
},
textShareButton: {
  padding: 10,
  borderRadius: 5,
  marginTop: -30,   // margen superior
  marginBottom: 10, // margen inferior
  alignItems: 'center',
  justifyContent: 'center',
},
textShareButtonText: {
  color: 'gray', // color de texto blanco
  fontSize: 16,
},
nightModeTextShareButtonText: {
  color: 'gray', // Color del texto en modo oscuro
},
generatingText: {
  marginTop: 10,
  fontSize: 16,
  color: '#333',
  textAlign: 'center' // Esto es para centrar el texto si lo deseas.
},
nightModeShareButtonText: {
  color: 'gray', // Color del texto en modo oscuro
},

    
  };



  const OPENAI_API_KEY = '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'; // Tu clave de API de OpenAI

  useEffect(() => {
    if (imageUrl === null) {
      // Simula la respuesta del bot para dar una bienvenida inicial
      const initialBotMessage = greetingsTranslations[selectedLanguage] || greetingsTranslations.en;
      const botMessage = { role: 'bot', message: initialBotMessage };
      setChatLog([botMessage]);
    }
  }, [imageUrl]);

  const handleGenerateImage = async () => {
    setIsLoading(true);
    
    setLoadingMessage(generatingTranslations.imageText[selectedLanguage]);  // Establece el primer mensaje
    
    setTimeout(() => {
        setLoadingMessage(generatingTranslations.text[selectedLanguage]);  // Cambia al segundo mensaje después de 3 segundos
    }, 8000);

    setIsInputDisabled(true); // Deshabilita los inputs al inicio
    try {
        const response = await axios.post(
            'https://api.openai.com/v1/images/generations',
            {
                prompt: breed,
                n: 1,
                size: '512x512',
            },
            {
                headers: {
                    Authorization: `Bearer ${OPENAI_API_KEY}`,
                    'Content-Type': 'application/json',
                },
            }
        );
        setImageUrl(response.data.data[0].url);

        // No añadiremos el mensaje del usuario al chatLog. Solo agregaremos la respuesta del bot.
        const botResponse = await getGPTResponse(breed);
        
        // Ahora, estableceremos el chatLog para mostrar solo la respuesta del bot.
        setChatLog([{ role: 'bot', message: botResponse }]);

  
    } catch (error) {
        console.error('Error generando imagen:', error);
    } finally {
        setIsLoading(false);
        setIsInputDisabled(false);
    }
};

  const getGPTResponse = async (userMessage) => {
    // Añadir el nuevo mensaje del usuario al final de chatLog
    const fullConversation = [...chatLog, { role: 'user', message: userMessage }];

    // Si la conversación está vacía, agregar el prompt inicial.
    if (fullConversation.length === 1) {
      fullConversation.unshift({
        role: 'system',
        message: 'Eres un experto en razas  de perros. Responde las preguntas con ese conocimiento.'
      });
    }

    // Solo toma los últimos 10 mensajes para evitar exceder el límite de tokens
    const recentMessages = fullConversation.slice(-10).map(item => {
      // Cambiar 'bot' por 'assistant'
      if (item.role === 'bot') {
        item.role = 'assistant';
      }
      return item;
    });

    const apiUrl = "https://api.openai.com/v1/chat/completions";

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${OPENAI_API_KEY}`
      },
      body: JSON.stringify({
        model: "gpt-3.5-turbo",
        messages: recentMessages.map(item => ({
          role: item.role,
          content: item.message
        })),
        max_tokens: 500
      })
    };

    const response = await fetch(apiUrl, requestOptions);
    const result = await response.json();

    // Verificar el resultado devuelto por la API
    if (response.ok && result.choices && result.choices[0] && result.choices[0].message) {
      return result.choices[0].message.content.trim();
    } else {
      console.error("Error de la API:", result);
      throw new Error(result.error || "Unexpected API response format");
    }
  };

  const handleSendMessage = async () => {
    setIsLoading(true); // Muestra la barra de progreso
    setIsInputDisabled(true); // Deshabilita los inputs al inicio
    setShowShareButton(false); // Al inicio, ocultar el botón

    const userMessage = inputText;

    // Configurar el mensaje del usuario como un mensaje pendiente
    setPendingMessage({ role: 'user', message: userMessage });

    try {
      const response = await getGPTResponse(userMessage);
      
      // Una vez que el bot responda, eliminar el mensaje pendiente y actualizar el chatLog con la respuesta del bot
      setPendingMessage(null);
      const botResponse = { role: 'bot', message: response };

  
      setChatLog(prevChatLog => [...prevChatLog, botResponse]);

      // Limpiar el input después de enviar el mensaje
      setInputText('');

      // Cierra el teclado
      Keyboard.dismiss();

      // Desplazarse al final después de un ligero retraso para dar tiempo al renderizado
      setTimeout(() => {
        if (scrollViewRef.current) {
          scrollViewRef.current.scrollToEnd({ animated: true });
        }
      }, 10);
    } catch (error) {
      console.error("Error:", error);
      setPendingMessage(null); // Limpiar el mensaje pendiente en caso de error
    }
    setIsChatExpanded(true);
    setIsLoading(false);
    setIsInputDisabled(false);
    setShowShareButton(true);  // Una vez que tengas la respuesta, muestra el botón


  };

  const handleQuestion = () => {
    // Establece el mensaje del usuario como mensaje pendiente
    setPendingMessage({ role: 'user', message: inputText });

    // Limpia el chatLog
    setChatLog([]);

    // Limpia el texto ingresado
    setInputText('');

    // Luego, quizás quieras llamar a handleSendMessage para procesar la pregunta
    handleSendMessage();
};

  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };
  
const getPermissionAsync = async () => {
  const { status } = await MediaLibrary.requestPermissionsAsync();
  if (status !== 'granted') {
      alert('Lo sentimos, necesitamos permisos para acceder a tus fotos!');
      return false;
  }
  return true;
}

const shareBotResponse = async (botMessage) => {
  try {
    await Share.share({
      message: botMessage,
      title: 'Respuesta del Bot' // Opcional, puedes omitirlo si no lo deseas
    });
  } catch (error) {
    console.error("Error compartiendo el mensaje del bot:", error.message);
  }
};


const shareImage = async () => {
  try {
    if (Platform.OS === 'web') {
      // Im Webbrowser ist es nicht notwendig, das Bild herunterzuladen
      // Verwende einfach die Web Share API, wenn sie verfügbar ist
      if (navigator.share) {
        navigator.share({
          title: 'Bild teilen',
          url: imageUrl // Annahme, dass imageUrl eine gültige und zugängliche URL ist
        }).then(() => {
          console.log('Bild erfolgreich geteilt');
        }).catch((error) => {
          console.error("Fehler beim Teilen des Bildes im Web:", error.message);
          alert('Es gab ein Problem beim Teilen des Bildes im Web. Bitte versuchen Sie es erneut.');
        });
      } else {
        console.log('Web Share API nicht verfügbar');
        alert('Die Teilen-Option ist in Ihrem Browser nicht verfügbar.');
      }
    } else {
      alert("Die Teilen-Option ist nur auf der Webplattform verfügbar!");
    }
  } catch (error) {
    console.error("Fehler:", error.message);
    alert('Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es erneut.');
  }
};


  return (
    <ScrollView 
        ref={scrollViewRef}
        style={{flex: 1}} contentContainerStyle={{ flexGrow: 1 }}>
         <View style={[styles.modalContainer, isNightMode && styles.nightModeSection]}>
        {isLoading && (
    <View style={styles.progressContainer}>
        <Progress.Bar indeterminate width={200} />
        <Text style={styles.generatingText}>
            {loadingMessage}
        </Text>
    </View>
)}

            <View style={[styles.contentContainer, isNightMode && styles.nightModeSection]}>
                {imageUrl === null ? (
                    <View style={styles.inputContainer}>
                        <View style={[
                            styles.textContainer,
                            isNightMode ? styles.textNightMode : styles.textDayMode,
                            styles.marginContainer
                        ]}>
                            <Text style={styles.text}>
                                {placeholdersi[selectedLanguage] || placeholdersi.en}
                            </Text>
                            <Image
                                source={{ uri: 'https://app.hundezonen.ch/docs/dog1%20copdia%202.jpg' }}
                                style={{ width: '100%', height: 150, borderRadius: 20, marginTop: 10 }}
                            />
                        </View>
            <TextInput
              style={[styles.textInput, isNightMode && styles.nightModeInput]}
              placeholder={placeholders[selectedLanguage] || placeholders.en}
              value={breed}
              onChangeText={setBreed}
              placeholderTextColor={isNightMode ? '#aaa' : '#000'}
              editable={!isInputDisabled} // Agrega esta línea
            />
            <TouchableOpacity
              style={[styles.button, isNightMode && styles.nightModeButton]}
              onPress={handleGenerateImage}
            >
              <Text style={[styles.buttonText, isNightMode && styles.nightModeButtonText]}>
                {generateImageTranslations[selectedLanguage] || generateImageTranslations.en}
              </Text>
            </TouchableOpacity>
            <View style={styles.chipContainer}>
    {dogBreeds.map((breed, index) => (
        <TouchableOpacity 
            key={index} 
            style={styles.chip}
            onPress={() => handleBreedSelect(breed)}
        >
            <Text style={styles.chipText}>{breed}</Text>
        </TouchableOpacity>
    ))}
</View>
          </View>
          
        ) : (
          
            <View>
              {/* Muestra el botón de compatrtir imagen solo si la imagen está lista y la barra de progreso ha terminado */}
              {!isLoading && (
 <TouchableOpacity 
 style={[styles.shareButton, isNightMode && styles.nightModeShareButton]}
 onPress={shareImage}
>
 <Icon name="share-alt" size={30} color={isNightMode ? 'gray' : 'gray'} /> 
 <Text style={[styles.shareButtonText, isNightMode && styles.nightModeShareButtonText]}>
     {shareImageButtonTranslations[selectedLanguage] || shareImageButtonTranslations.en}
 </Text>
</TouchableOpacity>

)}

<TouchableOpacity onPress={shareImage}>
    <Image 
        source={{ uri: imageUrl }} 
        style={isChatExpanded ? styles.smallImage : styles.image} 
    />
</TouchableOpacity>
            <TextInput
              style={[styles.textInput, isNightMode && styles.nightModeInput]}
              value={inputText}
              onChangeText={setInputText}
              placeholder={messagePlaceholderTranslations[selectedLanguage] || messagePlaceholderTranslations.en}
              placeholderTextColor={isNightMode ? '#aaa' : '#000'}
              editable={!isInputDisabled} // Agrega esta línea
            />
            <TouchableOpacity
              style={[styles.button, isNightMode && styles.nightModeButton]}
              onPress={handleQuestion} // Cambiado para manejar la pregunta primero
            >
              <Text style={[styles.buttonText, isNightMode && styles.nightModeButtonText]}>
                {sendTranslations[selectedLanguage] || sendTranslations.en}
              </Text>
            </TouchableOpacity>
            <View style={isChatExpanded ? [styles.largeChatContainer, isNightMode && styles.nightModeChatContainer] : [styles.chatContainer, isNightMode && styles.nightModeChatContainer]}>
              {/* Mostrar el mensaje pendiente del usuario */}
              {pendingMessage && (
                <Text style={[styles.chatMessage, styles.userMessage, isNightMode && styles.nightModeChatMessage]}>
                  {pendingMessage.role}: {pendingMessage.message}
                </Text>
              )}
{chatLog.map((message, index) => (
    <View key={index} style={{ marginBottom: 10 }}> 
        {/* Mostrar el mensaje */}
        <Text 
            style={[
                styles.chatMessage, 
                message.role === 'bot' ? styles.botMessage : styles.userMessage, 
                isNightMode && styles.nightModeChatMessage
            ]}
        >
            {message.role !== 'user' && message.role !== 'bot' ? message.role + ': ' : ''}{message.message}
        </Text>
    
        {/* Mostrar el botón de compartir si el mensaje es del bot */}
        {message.role === 'bot' && (
  <TouchableOpacity 
  style={[styles.textShareButton, isNightMode && styles.nightModeTextShareButton]}
  onPress={() => shareBotResponse(message.message)}
>
  <Text style={[styles.textShareButtonText, isNightMode && styles.nightModeTextShareButtonText]}>
      {shareButtonTranslations[selectedLanguage] || shareButtonTranslations.en}
  </Text>
</TouchableOpacity>

        )}
    </View>
))}

            </View>
          </View>
        )}
      </View>
    </View>
    </ScrollView>
);

  
}

export default DogBreedModal;
